import React, { useState, useEffect } from "react"
import { getValue } from "../../utils"
import Form from "../Form"
import Loader from "../UI/Loader"
import Message from "../UI/Message"
import { Button } from "rebass/styled-components"

import { connect } from "react-redux"
import {
  getUser,
  update,
  user_check_code,
} from "../../redux/actions/currentUserActions"
import { getErrorMessage } from "../../utils"

const CodeValidation = ({ dispatch, user }) => {
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (user) {
      if (
        getValue(user, "field_phone", "0.value", "") !== "" &&
        !getValue(user, "field_phone_check", "0.value")
      ) {
        // dispatch(user_create_code(getValue(user, "uid", "0.value", "")))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const removePhoneNum = () => {
    dispatch(
      update(
        {
          field_phone: [
            {
              value: "",
            },
          ],
        },
        getValue(user, "uid", "0.value", "")
      )
    )
  }

  const formData = {
    formId: "codevalidation",
    successMessage: "Votre numéro a bien été validé.",
    elements: [
      {
        type: "text",
        name: "code",
        default: "",
        label: "Entrez le code reçu par SMS",
        required: true,
        props: {
          width: [1],
        },
      },
      {
        type: "markup",
        props: {
          width: [1, 1, 1 / 2],
        },
        markup: (
          <Button
            as="div"
            width={1}
            variant="primaryOutline"
            onClick={() => removePhoneNum()}
          >
            Changer de numéro
          </Button>
        ),
      },
      {
        type: "submit",
        label: "Valider",
        props: {
          width: [1, 1, 1 / 2],
        },
        buttonProps: {
          width: [1],
          variant: "primaryGradiant",
        },
      },
    ],
  }
  const onSubmit = formValues => {
    formValues.uid = user.uid[0].value

    setMessages([])
    setLoading(true)
    dispatch(user_check_code(formValues.uid, formValues.code)).then(action => {
      if (action.type === "USER_CHECK_CODE_SUCCESS") {
        if (action.payload.data.success) {
          dispatch(getUser(formValues.uid))
        } else {
          setLoading(false)
          setMessages([{ status: "error", value: action.payload.data.error }])
        }
      }
      if (action.type === "USER_CHECK_CODE_FAIL") {
        setLoading(false)
        setMessages([
          ...messages,
          { status: "error", value: getErrorMessage(action.error.response) },
        ])
      }
    })
  }
  return (
    <>
      {messages.map((message, key) => (
        <Message
          key={key}
          status={message.status}
          mb={2}
          onClose={() =>
            setMessages(messages.filter((value, index) => index !== key))
          }
        >
          {message.value}
        </Message>
      ))}
      {loading && <Loader message="Validation du code en cours" />}
      {!loading && (
        <Form
          data={formData}
          onSubmit={(formValues, data, submitSuccess) => {
            onSubmit(formValues, data, submitSuccess)
          }}
        />
      )}
    </>
  )
}

const mapStateToProps = state => ({
  user: state.currentUser.user,
})
export default connect(mapStateToProps)(CodeValidation)
