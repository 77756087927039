import React from "react"
import { Text } from "rebass/styled-components"
import { getOffre } from "../../../utils"
import Badge from "../Badge"

const BadgeOffre = ({node }) => {


const offre = getOffre(node)
  return (
    <Badge variant={"badge.offre" + offre}>
      <Text variant="caps">{offre}</Text>
    </Badge>
  )
}
export default BadgeOffre
