import React from "react"
import { Heading, Box, Text, Flex } from "rebass/styled-components"
import Block from "./Block"
import { connect } from "react-redux"
import { getValue, createCategorieBreadCrumb } from "../../../utils"
import IconPlan from "../icons/IconPlan"
const Private = ({ node, user }) => {
  if (user?.roles) {
var hasRole =
  user?.roles.filter((role) => role.target_id === "equipe_plan").length > 0

    if (hasRole) {
      const categories = getValue(node, "field_co_cats", "", [])

      return (
        <Box>
          <Block title={`Infos privées de ${node.title}`}>
            <Box py={2}>
              <Heading as="h3" variant="h3">
                Catégories
              </Heading>
              {categories.map((categorie) => {
                const crumbs = createCategorieBreadCrumb(categorie)

                return (
                  <Flex key={"categorie-" + categorie.drupal_internal__tid}>
                    {crumbs.map((crumb, i) => (
                      <Flex key={crumb.path}>
                        {i > 0 && (
                          <IconPlan mx={1} icon="arrow_right" size={14} />
                        )}
                        <Text>{crumb.title}</Text>
                      </Flex>
                    ))}
                  </Flex>
                )
              })}
            </Box>
            <Box py={2}>
              <Heading as="h3" variant="h3">
                Mots clés
              </Heading>
              <Text>{getValue(node, "field_co_keywords", "", "")}</Text>
            </Box>
          </Block>
        </Box>
      )
    }
  }
  return null
}
const mapStateToProps = (state) => ({
  user: state.currentUser.user,
})
export default connect(mapStateToProps)(Private)
