import React, { useState, useEffect } from "react"
import axios from "axios"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import Message from "../UI/Message"
import {
  Label,
  Input,
  Select,
  Textarea,
  Radio,
  Checkbox,
} from "@rebass/forms/styled-components"
import { Box, Text, Button, Flex } from "rebass/styled-components"
import PasswordMeter from "./PasswordMeter"
import AutocompleteSelect from "./AutocompleteSelect"
// import Loader from "../UI/Loader"
import IconPlan from "../UI/icons/IconPlan"
import { IMaskInput } from "react-imask"
import {  axiosInstance } from "../../utils"

const isValidEmail = (email) => {
  var emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
  return email.search(emailRegEx) !== -1
}

const Form = ({ data, onSubmit, onChange, successComp }) => {
  const SuccessComp = successComp || null
  const [messages, setMessages] = useState([])
  const [visiblePassword, setVisiblePassword] = useState([])
  const [formValues, setFormValues] = useState({})
  const [hasError, setHasError] = useState(false)
  const [sent, setSent] = useState(false)
  const [sending, setSending] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()
  useEffect(() => {
    const defaultValues = {}
    data.elements.map(function (formElement) {
      if (formElement.type !== "markup" && formElement.type !== "submit") {
        defaultValues[formElement.name] = formElement.default
      }
      return ""
    })
    setFormValues({
      ...defaultValues,
      ...formValues,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  data = data || { formId: "", elements: [], successMessage: "" }
  data.submitLabel = data.submitLabel || "Envoyer"
  const handleChange = (valueName, value) => {
    setFormValues({
      ...formValues,
      [valueName]: value,
    })
    if (typeof onChange === "function")
      onChange({
        ...formValues,
        [valueName]: value,
      })
  }
  const defaultOnSubmit = (formValues, data, submitSuccess) => {
    if (data.formUrl) {
      axios.post(data.formUrl, { body: formValues }).then((res) => {
        // console.log(res)
        submitSuccess()
      })
    }
  }
  onSubmit = onSubmit || defaultOnSubmit
  const handleSubmitWithCaptcha = async (e) => {
    e.preventDefault()
    if (data.useCaptcha) {
      if (executeRecaptcha) {
        const token = await executeRecaptcha(data.formId)
        axiosInstance
          .get(`/api/v2/check_recaptcha/${data.formId}/${token}`, {
            headers: {
              "X-AUTH-TOKEN": "",
            },
          })
          .then((res) => {
            if (res.data.success) {
              handleSubmit(e)
            }
          })
      }
    } else {
      handleSubmit(e)
    }
  }

  const handleSubmit = (e) => {
    setSending(true)
    e.preventDefault()
    const newMessages = []
    var hasTempError = false

    data.elements.map(function (formElement) {
      if (formElement.type === "markup" && formElement.type === "submit") {
        return ""
      }
      var currentValue = formValues[formElement.name] || ""
      if (
        formElement.required &&
        (currentValue === "" ||
          (Array.isArray(currentValue) && currentValue.length === 0))
      ) {
        hasTempError = true
        newMessages.push('Le champ "' + formElement.label + '" est requis.')
      }
      if (formElement.type === "text") {
        if (formElement.imputType === "email") {
          if (!isValidEmail(currentValue)) {
            hasTempError = true
            newMessages.push("Email non valide.")
          }
        }
      }
      if (formElement.type === "textDate") {
        let pattern =
          /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/
        let result = pattern.test(currentValue)
        if (!result) {
          // hasTempError = true
          // newMessages.push("Date non valide.")
        }
      }

      if (formElement.type === "email") {
        if (!isValidEmail(currentValue)) {
          hasTempError = true
          newMessages.push("Email non valide.")
        }
      }
      if (formElement.verification) {
        let error = formElement.verification(formValues, data)
        if (error) {
          hasTempError = true
          newMessages.push(error)
        }
      }
      return ""
    })
    setMessages(newMessages)
    setHasError(hasTempError)
    if (!hasTempError) {
      onSubmit(formValues, data, submitSuccess)
    } else {
      setSending(false)
    }
  }
  const submitSuccess = () => {
    setFormValues({})
    setMessages([data.successMessage])
    setSent(true)
    setSending(false)
    // var timer = setTimeout(() => {
    //   setMessages([])
    //   setSent(false)
    // }, 3000);
    // return () => clearTimeout(timer);
  }
  return (
    <Box>
      {/* {sending && (
        <Loader
          message="Envoi en cours"
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "#ffffffbf",
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        />
      )} */}

      {messages.length > 0 && ((sent && successComp === null) || !sent) && (
        <Message
          status={hasError ? "error" : "success"}
          mb={2}
          onClose={() => {
            setMessages([])
            if (sent) {
              setSent(false)
            }
          }}
        >
          {messages.map((message, index) => (
            <Text key={`contact-form-message-${index}`}>{message}</Text>
          ))}
        </Message>
      )}
      {sent && successComp !== null && (
        <>
          <SuccessComp />
          <Box pt={4} textAlign="center">
            <Button
              onClick={() => {
                setMessages([])
                setSent(false)
              }}
            >
              Retour
            </Button>
          </Box>
        </>
      )}
      <form
        onSubmit={(e) => {
          handleSubmitWithCaptcha(e)
        }}
        name={data.formId}
        id={data.formId}
      >
        <input
          type="hidden"
          name="form-name"
          aria-label="form-name"
          value={data.formId}
        ></input>
        {!sent && (
          <Box>
            <Flex flexWrap="wrap" mx={-2}>
              {data.elements.map((formElement, i) => {
                const star = formElement.required ? (
                  <>
                     <span style={{ color: "red" }}>*</span>
                  </>
                ) : (
                  ""
                )
                formElement.options = formElement.options || [{ value: "" }]
                const elementProps = formElement.props || { width: "100%" }
if (formElement.type === "hidden"){
elementProps.display = "none"
}
  return (
    <Box key={"form-el-" + i} p={2} {...elementProps}>
      {formElement.type === "text" && (
        <>
          <Label htmlFor={formElement.name}>
            {formElement.label}
            {star}
          </Label>
          <Input
            id={formElement.name}
            name={formElement.name}
            defaultValue={formElement.default}
            placeholder={formElement.placeholder}
            readOnly={formElement.readOnly || false}
            onChange={(e) => {
              handleChange(formElement.name, e.target.value)
            }}
          />
        </>
      )}
      {formElement.type === "textDate" && (
        <Box
          sx={{
            input: {
              boxSizing: "border-box",
              margin: "0",
              minWidth: "0",
              display: "block",
              width: "100%",
              padding: "10px",
              fontSize: "inherit",
              lineHeight: "inherit",
              border: "1px solid",
              borderRadius: "4px",
              backgroundColor: "white",
              color: "#2F4858",
              borderColor: "#A0A9BA",
            },
          }}
        >
          <Label htmlFor={formElement.name}>
            {formElement.label}
            {star}
          </Label>

          <IMaskInput
            id={formElement.name}
            name={formElement.name}
            mask={Date}
            lazy={false}
            defaultValue={formElement.default}
            pattern="d/m/Y"
            min={new Date(1900, 0, 1)}
            max={new Date()}
            format={(date) => {
              var day = date.getDate()
              var month = date.getMonth() + 1
              var year = date.getFullYear()

              if (day < 10) day = "0" + day
              if (month < 10) month = "0" + month

              return [day, month, year].join("/")
            }}
            parse={(str) => {
              var yearMonthDay = str.split("/")
              return new Date(
                yearMonthDay[2],
                yearMonthDay[1] - 1,
                yearMonthDay[0]
              )
            }}
            unmask={false}
            onAccept={(value, mask) => {
              handleChange(formElement.name, value)
            }}
          />
        </Box>
      )}

      {formElement.type === "hidden" && (
        <>
          <Input
            display="none"
            type="hidden"
            id={formElement.name}
            name={formElement.name}
            defaultValue={formElement.default}
            placeholder={formElement.placeholder}
            readOnly={true}
            onChange={(e) => {
              handleChange(formElement.name, e.target.value)
            }}
          />
        </>
      )}
      {formElement.type === "email" && (
        <>
          <Label htmlFor={formElement.name}>
            {formElement.label}
            {star}
          </Label>
          <Input
            id={formElement.name}
            name={formElement.name}
            type="email"
            defaultValue={formElement.default}
            placeholder={formElement.placeholder}
            onChange={(e) => {
              handleChange(formElement.name, e.target.value)
            }}
          />
        </>
      )}
      {formElement.type === "password" && (
        <>
          <Label htmlFor={formElement.name}>
            {formElement.label}
            {star}
          </Label>
          <Box sx={{ position: "relative" }}>
            <Input
              id={formElement.name}
              name={formElement.name}
              type={
                visiblePassword.indexOf(formElement.name) === -1
                  ? "password"
                  : "text"
              }
              defaultValue={formElement.default}
              onChange={(e) => {
                handleChange(formElement.name, e.target.value)
              }}
            />
            <IconPlan
              onClick={() => {
                if (visiblePassword.indexOf(formElement.name) === -1) {
                  setVisiblePassword([...visiblePassword, formElement.name])
                } else {
                  const newVisiblePassword = visiblePassword.filter(
                    (el) => el !== formElement.name
                  )
                  setVisiblePassword(newVisiblePassword)
                }
              }}
              icon={
                visiblePassword.indexOf(formElement.name) === -1
                  ? "eye"
                  : "eye-blocked"
              }
              sx={{
                position: "absolute",
                cursor: "pointer",
                right: 0,
                top: 0,
                height: "100%",
                padding: "15px",
              }}
            />
          </Box>
          {formElement.passwordmeter && (
            <PasswordMeter
              value={formValues[formElement.name]}
              name={formValues[formElement.passwordmeter]}
              showVerdicts={true}
            />
          )}
          {formElement.passwordchecker &&
            formValues[formElement.passwordchecker] !== "" && (
              <Text pt={2}>
                {formValues[formElement.passwordchecker] ===
                formValues[formElement.name]
                  ? "Les mots de passe correspondent."
                  : "Les mots de passe ne correspondent pas."}
              </Text>
            )}
        </>
      )}
      {formElement.type === "textarea" && (
        <>
          <Label htmlFor={formElement.name}>
            {formElement.label}
            {star}
          </Label>
          <Textarea
            id={formElement.name}
            name={formElement.name}
            defaultValue={formElement.default}
            placeholder={formElement.placeholder}
            onChange={(e) => {
              handleChange(formElement.name, e.target.value)
            }}
          />
        </>
      )}
      {formElement.type === "radio" && (
        <>
          <Label htmlFor={formElement.name}>
            {formElement.label}
            {star}
          </Label>
          {formElement.options.map((option, iOption) => (
            <Label key={iOption} alignItems="center">
              <Radio
                id={formElement.name + "-" + iOption}
                name={formElement.name}
                value={option.value}
                onChange={(e) => {
                  handleChange(
                    formElement.name,
                    e.target.checked ? e.target.value : ""
                  )
                }}
                defaultChecked={option.value === formElement.default}
              />
              {option.label}
            </Label>
          ))}
        </>
      )}
      {formElement.type === "checkbox" && (
        <>
          <Label htmlFor={formElement.name}>
            {formElement.label}
            {star}
          </Label>
          <Box>{formElement.placeholder}</Box>
          {formElement.options.map((option, iOption) => (
            <Label key={iOption} alignItems="center">
              <Checkbox
                id={formElement.name + "-" + iOption}
                name={formElement.name}
                value={option.value}
                onChange={(e) => {
                  handleChange(
                    formElement.name,
                    e.target.checked ? e.target.value : ""
                  )
                }}
                defaultChecked={option.value === formElement.default}
              />
              {option.label}
            </Label>
          ))}
        </>
      )}
      {formElement.type === "checkboxes" && (
        <>
          <Label
            htmlFor={formElement.name}
            // flexWrap={["wrap", "wrap", "nowrap"]}
          >
            <Text width={[1, 1, "auto"]}>
              {formElement.label}
              {star}
            </Text>
            <Text
              sx={{ fontStyle: "italic" }}
              color="gray"
              width={[1, 1, "auto"]}
            >
              {formElement.placeholder}
            </Text>
          </Label>
          {formElement.options.map((option, iOption) => {
            const currentVal = formValues[formElement.name] || []
            const checkedClass =
              Array.isArray(currentVal) &&
              currentVal.indexOf(option.value) !== -1
                ? "checked"
                : ""
            return (
              <Label
                key={iOption}
                alignItems="center"
                className={
                  "checkbox checkbox-" +
                  formElement.name +
                  " checkbox-" +
                  option.value +
                  " " +
                  checkedClass
                }
              >
                <Checkbox
                  id={formElement.name + "-" + iOption}
                  name={formElement.name}
                  value={option.value}
                  onChange={(e) => {
                    formValues[formElement.name] =
                      formValues[formElement.name] || []
                    const currentItemValues = [...formValues[formElement.name]]
                    if (currentItemValues.indexOf(e.target.value) !== -1) {
                      currentItemValues.splice(
                        currentItemValues.indexOf(e.target.value),
                        1
                      )
                    }
                    if (e.target.checked) {
                      currentItemValues.push(e.target.value)
                    }
                    handleChange(formElement.name, currentItemValues)
                  }}
                  defaultChecked={option.value === formElement.default}
                />
                {typeof option.label === "function"
                  ? option.label()
                  : option.label}
              </Label>
            )
          })}
        </>
      )}
      {formElement.type === "select" && (
        <>
          <Label htmlFor={formElement.name}>
            {formElement.label}
            {star}
          </Label>
          {formElement.options.length > 50 && (
            <AutocompleteSelect
              currentValue={formValues[formElement.name]}
              formElement={formElement}
              onChange={(e) => {
                handleChange(
                  formElement.name,
                  e.target.selectedOptions ? e.target.value : ""
                )
              }}
            />
          )}
          {formElement.options.length <= 50 && (
            <Select
              id={formElement.name}
              name={formElement.name}
              defaultValue={formElement.default}
              onChange={(e) => {
                handleChange(
                  formElement.name,
                  e.target.selectedOptions ? e.target.value : ""
                )
              }}
            >
              {formElement.options.map((optionObj, iOption) => (
                <option key={iOption} value={optionObj.value}>
                  {optionObj.label}
                </option>
              ))}
            </Select>
          )}
        </>
      )}
      {formElement.type === "markup" && <>{formElement.markup}</>}
      {formElement.type === "submit" && (
        <Button type="submit" {...formElement.buttonProps}>
          {formElement.label}
        </Button>
      )}
    </Box>
  )
              })}
            </Flex>
          </Box>
        )}
      </form>
    </Box>
  )
}
export default Form
