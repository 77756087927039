import {
  SOLR_RESET,
  FETCH_SOLR_LOAD,
  SOLR_SET_WORD,
  FETCH_SOLR_LOAD_SUCCESS,
  SOLR_SET_ITEMS,
  SOLR_RESET_FILTERS,
  SOLR_RESET_ITEMS,
  SOLR_SET_LAST_LIST_PATH,
} from "../actions/SolrActions"

const initialState = {
  items: false,
  loading: false,
  word: "",
  lastWord: "",
  city: false,
  lastCity: false,
  categorie: false,
  lastCategorie: false,
  categorieParent: false,
  lastCategorieParent: false,
  error: null,
  activeFilters: false,
  facetsCategories: {},
  facetsVilles: {},
  spellcheckSuggestions: [],
  lastListPath: false,
  searchPos: false,
}

export default function resultReducer(state = initialState, action) {
  switch (action.type) {
    case SOLR_SET_LAST_LIST_PATH:
      return {
        ...state,
        lastListPath: action.path,
      }

    case SOLR_SET_WORD:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        word: action.word,
        loading: false,
        city: false,
        lastCity: false,
        categorie: false,
        lastCategorie: false,
        categorieParent: false,
        lastCategorieParent: false,
        activeFilters: false,
      }

    case FETCH_SOLR_LOAD:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        loading: true,
        categorie: action.payload.categorie,
        categorieParent: action.payload.categorieParent,
        city: action.payload.city,
        searchPos: action.payload.searchPos,
      }
    case FETCH_SOLR_LOAD_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        loading: false,
        lastWord: state.word,
        lastCity: state.city,
        lastCategorie: state.categorie,
        lastCategorieParent: state.categorieParent,
        activeFilters: action.payload.data?.activeFilters,
        items: action.payload?.data?.items,
        facetsCategories:
          action.payload.data?.facet_count?.facet_fields?.im_field_categories,
        facetsVilles:
          action.payload.data?.facet_count?.facet_fields?.is_field_nom_ville,
        spellcheckSuggestions: action.payload?.data?.spellcheckSuggestions,
      }
    case SOLR_RESET_FILTERS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        city: false,
        lastCity: false,
        categorie: false,
        lastCategorie: false,
        categorieParent: false,
        lastCategorieParent: false,
        activeFilters: false,
      }
    case SOLR_RESET_ITEMS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        items: false,
      }
    case SOLR_SET_ITEMS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        items: action.items,
      }

    case SOLR_RESET:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return initialState
    default:
      // ALWAYS have a default case in a reducer
      return state
  }
}
