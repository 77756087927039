import React, { useState } from "react"
import Form from "../Form"
import Loader from "../UI/Loader"
import Message from "../UI/Message"
import { getErrorMessage } from "../../utils"

import { connect } from "react-redux"
import {
  user_pass_reset,
  login,
  getUser,
} from "../../redux/actions/currentUserActions"

const NewPassword = ({ dispatch, uid, timestamp, hashed_pass }) => {
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(false)

  const formData = {
    formId: "newpassword",
    successMessage: "Votre compte a bien été mis à jour.",
    elements: [
      {
        type: "password",
        name: "pass",
        default: "",
        label: "Nouveau mot de passe",
        required: true,
        props: {
          width: [1, 1, 1 / 2],
        },
      },
      {
        type: "password",
        name: "pass_check",
        default: "",
        label: "Confirmer le nouveau mot de passe",
        required: true,
        passwordchecker: "pass",
        props: {
          width: [1, 1, 1 / 2],
        },
        verification: function (values, data) {
          if (
            values.current_pass === values.pass &&
            values.current_pass !== ""
          ) {
            return "le nouveau mot de passe est identique a l'ancien."
          }
          return false
        },
      },
      {
        type: "submit",
        label: "Enregister",
        props: {
          width: 1,
          display: "flex",
          justifyContent: "center",
        },
        buttonProps: {
          width: [1, 1 / 2],
          variant: "primaryGradiant",
        },
      },
    ],
  }
  const onSubmit = (formValues) => {
    setMessages([])
    setLoading(true)
    dispatch(
      user_pass_reset(uid, timestamp, formValues.pass, hashed_pass)
    ).then((action) => {
      if (action.type === "USER_PASS_RESET_FAIL") {
        setLoading(false)
        setMessages([
          ...messages,
          { status: "error", value: getErrorMessage(action.error.response) },
        ])
      }
      if (action.type === "USER_PASS_RESET_SUCCESS") {
        setLoading(true)
        dispatch(
          login(
            { login: action.payload.data.mail, password: formValues.pass },
            true
          )
        ).then((action) => {
          if (action.type === "USER_LOGIN_FAIL") {
            setLoading(false)
            setMessages([
              {
                status: "error",
                value: getErrorMessage(action.error.response),
              },
            ])
          }
          if (action.type === "USER_LOGIN_SUCCESS") {
            dispatch(getUser(action.payload.data.user.uid))
          }
        })
      }
    })
  }
  return (
    <>
      {messages.map((message, key) => (
        <Message
          key={key}
          status={message.status}
          mb={2}
          onClose={() =>
            setMessages(messages.filter((value, index) => index !== key))
          }
        >
          {message.value}
        </Message>
      ))}
      {loading && <Loader message="Modification du mot de passe cours" />}
      {!loading && (
        <Form
          data={formData}
          onSubmit={(formValues, data, submitSuccess) => {
            onSubmit(formValues, data, submitSuccess)
          }}
        />
      )}
    </>
  )
}

export default connect()(NewPassword)
