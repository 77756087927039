import React, { useEffect } from "react"
import { Router } from "@reach/router"
import { connect } from "react-redux"
import Page from "../templates/Page"
import { navigate } from "gatsby"
import NewPassword from "../components/User/NewPassword"

const mapStateToProps = (state) => ({
  user: state.currentUser.user,
})
const UserPage = connect(mapStateToProps)(({
  location,
  uid,
  timestamp,
  hashed_pass,
  user,
}) => {
  // useEffect(() => {
  //   if (uid && timestamp && hashed_pass) {
  //     dispatch(user_reset(uid, timestamp, hashed_pass)).then((action) => {
  //       if (action.type === "USER_RESET_SUCCESS") {
  //         setPassToken(action.payload.data.pass_reset_token);
  //       }
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [uid, timestamp, hashed_pass]);
  useEffect(() => {
    if (user) {
      navigate("/mon-compte")
    }
  }, [user])

  return (
    <Page title="Page utilisateur" location={location} forceMap={false}>
      {uid && timestamp && hashed_pass && (
        <NewPassword
          timestamp={timestamp}
          uid={uid}
          hashed_pass={hashed_pass}
        />
      )}
    </Page>
  )
})
const App = ({ location }) => {
  return (
    <div className="app">
      <Router>
        <UserPage
          path="/user/reset/:uid/:timestamp/:hashed_pass"
          location={location}
        />
      </Router>
    </div>
  )
}

export default connect()(App)
