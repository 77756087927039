import React, { useState } from "react"
import { Link } from "gatsby"
import { Box, Button, Flex, Text } from "rebass/styled-components"
import { withTheme } from "styled-components"
import { connect } from "react-redux"
import { getValue } from "../../utils"
import { motion, AnimatePresence } from "framer-motion"
import IconPlan from "../UI/icons/IconPlan"

export const userMenuItems = [
         {
           id: "profil",
           label: "Mon compte",
           title: "Informations de compte",
           icon: "user_fill",
           iconColor: "primary",
           link: "/mon-compte",
         },
         {
           id: "rdvs",
           label: "Mes rendez-vous",
           title: "Mes rendez-vous",
           icon: "calendar_fill",
           link: "/mon-compte#rdvs",
         },
         {
           id: "favoris",
           label: "Mes favoris",
           title: "Mes favoris",
           icon: "start_outline",
           link: "/mon-compte#favoris",
         },
         {
           id: "inscription",
           label: "Inscrire mon entreprise",
           title: "Inscrire mon entreprise",
           icon: "plan-carte",
           link: "/mon-compte#inscription",
         },
         {
           id: "settings",
           label: "Paramètres",
           icon: "settings_outline",
           link: "/mon-compte#settings",
         },
         {
           id: "logout",
           label: "Se déconnecter",
           title: "Déconnexion",
           icon: "log-out",
           link: "/logout",
         },
       ]

const MenuLink = ({ icon, label, link, ...props }) => {
  return (
    <Link to={link}>
      <Button
        variant="ninja"
        width={[1]}
        py={2}
        px={3}
        {...props}
        sx={{ "&:hover": { backgroundColor: "gray10" } }}
      >
        <Flex alignItems="center">
          <IconPlan color="text" mr={2} icon={icon} size="24" />
          <Text color="text" sx={{ whiteSpace: "nowrap" }}>
            {label}
          </Text>
        </Flex>
      </Button>
      {link !== "/logout" && <Box height="1px" bg="gray10" />}
    </Link>
  )
}

const UserMenu = ({ isMap, user, location, theme }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Flex
      variant="headerButtonActive"
      onClick={() => {
        if (user) {
          setIsOpen(!isOpen)
        }
      }}
      ml={2}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50px",
        width: "50px",
        height: "50px",
        padding: "0 !important",
        position: "relative",
      }}
    >
      {!user && (
        <Link
          replace={false}
          to={"/mon-compte"}
          state={{ isMap: false }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: theme.colors.text,
          }}
        >
          <Flex alignItems="center">
            <IconPlan icon="user_fill" size="24" />
          </Flex>
        </Link>
      )}

      {user && (
        <>
          <Flex
            sx={{
              cursor: "pointer",
              width: "50px",
              height: "50px",
              alignItems: "center",
              justifyContent: "center",
              color: theme.colors.text,
            }}
          >
            <Flex alignItems="center">
              {getValue(user, "field_firstname", "0.value", "").charAt(0)}
              {getValue(user, "field_lastname", "0.value", "").charAt(0)}
            </Flex>
          </Flex>
          <Box
            bg="white"
            sx={{
              top: "60px",
              right: "-10px",
              position: "absolute",
            }}
          >
            <AnimatePresence initial={false}>
              {isOpen && (
                <motion.section
                  key="content"
                  initial="collapsed"
                  animate={isOpen ? "open" : "collapsed"}
                  exit="collapsed"
                  variants={{
                    open: {
                      opacity: 1,
                      y: 0,
                      height: "auto",
                    },
                    collapsed: {
                      opacity: 0,
                      y: 20,
                      height: 0,
                    },
                  }}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  <Box
                    sx={{
                      width: 0,
                      height: 0,
                      borderStyle: "solid",
                      borderWidth: "0 6px 6px 6px",
                      borderColor:
                        "transparent transparent #ffffff transparent",
                      top: "-6px",
                      right: "29px",
                      position: "absolute",
                    }}
                  ></Box>
                  <Box
                    bg="white"
                    sx={{
                      boxShadow: "headerButton",
                      top: "0",
                      right: "0",
                      position: "absolute",
                    }}
                  >
                    {userMenuItems.map((item) => (
                      <MenuLink {...item} key={item.id} />
                    ))}
                  </Box>
                </motion.section>
              )}
            </AnimatePresence>
          </Box>
        </>
      )}
    </Flex>
  )
}

const mapStateToProps = state => ({
  isMap: state.global.isMap,
  location: state.global.location,
  user: state.currentUser.user,
})
export default connect(mapStateToProps)(withTheme(UserMenu))
