export const SET_IS_MAP = "SET_IS_MAP"
export const SET_LOCATION = "SET_LOCATION"
export const TOGGLE_IS_MAP = "TOGGLE_IS_MAP"
export const TOGGLE_MAP_DRAWER = "TOGGLE_MAP_DRAWER"
export const SET_MAP_DRAWER_LEVEL = "SET_MAP_DRAWER_LEVEL"
export const SET_SHOW_FILTERS = "SET_SHOW_FILTERS"
export const SET_NEW_SESSION = "SET_NEW_SESSION"
export const SET_READY_TO_USE = "SET_READY_TO_USE"
export const TOGGLE_AROUNDME = "TOGGLE_AROUNDME"
export const SET_USER_POS = "SET_USER_POS"
export const SET_IS_MOBILE = "SET_IS_MOBILE"
export const SET_FIRST_LOCATION = "SET_FIRST_LOCATION"
