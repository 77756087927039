// import { PURGE } from 'redux-persist';

import { getNodePath } from "../../utils"
import { store } from "../store"
import {
  SET_IS_MAP,
  TOGGLE_IS_MAP,
  SET_LOCATION,
  TOGGLE_MAP_DRAWER,
  SET_SHOW_FILTERS,
  SET_NEW_SESSION,
  SET_MAP_DRAWER_LEVEL,
  SET_READY_TO_USE,
  SET_FIRST_LOCATION,
  TOGGLE_AROUNDME,
  SET_USER_POS,
  SET_IS_MOBILE,
} from "./globalActionNames"

export const setIsMobile = isMobile => {
  return {
    type: SET_IS_MOBILE,
    isMobile: isMobile,
  }
}

export const toggleIsMap = () => ({
  type: TOGGLE_IS_MAP,
})

export const toggleAroundMe = () => {
  return {
    type: TOGGLE_AROUNDME,
  }
}
export const setUserPosition = pos => {
  return {
    type: SET_USER_POS,
    position: pos,
  }
}

export const setReadyToUse = isReady => ({
  type: SET_READY_TO_USE,
  ready: isReady,
})

export const setFirstLocation = hasFirstLocation => ({
  type: SET_FIRST_LOCATION,
  hasFirstLocation: hasFirstLocation,
})

export const setIsMap = isMap => ({
  type: SET_IS_MAP,
  isMap: isMap,
})
export const setNewSession = newSession => ({
  type: SET_NEW_SESSION,
  newSession: newSession,
})
export const setLocation = (location, isMap) => {
  const currentIsMap = store.getState().global.isMap
  const node = store.getState().currentNode.item
  var mapDrawerOpen = store.getState().global.mapDrawerOpen
  isMap = isMap || currentIsMap
  if (location.pathname.indexOf("/entreprises/") === 0) {
    isMap = true
    mapDrawerOpen = true
  }

  if (node) {
    isMap = location.pathname === getNodePath(node, true)
  }

  if (location.hasOwnProperty("state")) {
    if (location.state) {
      if (location.state.hasOwnProperty("isMap")) {
        isMap = location.state.isMap
      }
      if (location.state.hasOwnProperty("mapDrawerOpen")) {
        mapDrawerOpen = location.state.mapDrawerOpen
      }
    }
  }
  if (location.pathname === "/") {
    mapDrawerOpen = true
    isMap = true
  }
  if (location.pathname.indexOf("/annuaire") === 0) {
    isMap = false
  }
  return {
    type: SET_LOCATION,
    location: location,
    isMap: isMap,
    mapDrawerOpen: mapDrawerOpen,
  }
}

export const setMapDrawerLevel = level => {
  level = level || 0
  level = [0, 1, 2].indexOf(level) > -1 ? level : 0
  var mapDrawerOpen = level > 0
  return {
    type: SET_MAP_DRAWER_LEVEL,
    level: level,
    isOpen: mapDrawerOpen,
  }
}
export const toggleMapDrawer = () => ({
  type: TOGGLE_MAP_DRAWER,
})

export const setShowFilters = showFilters => {
  showFilters = showFilters || false
  showFilters = showFilters !== false ? true : false
  return {
    type: SET_SHOW_FILTERS,
    showFilters: showFilters,
  }
}
