import React from "react"
import { Heading } from "rebass/styled-components"
const Title = ({ title, ...props }) => {
  return (
    <Heading as="h1" {...props}>
      {title}
    </Heading>
  )
}
export default Title
