import React from "react"
import { ThemeProvider } from "styled-components"
import { GlobalStyle, SiteWrapper } from "./src/theme/Site.style"
import theme from "./src/theme"
import ReduxWrapper from "./src/redux"
import styled from "styled-components"
import { ModalProvider, BaseModalBackground } from "styled-react-modal"

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: opacity ease 200ms;
  background-color: rgba(47, 72, 88, 0.8);
`

export const wrapRootElementWrapper = ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  // const store = createStore();
  return (
    <>
      <ReduxWrapper>
        <ThemeProvider theme={theme}>{element}</ThemeProvider>
      </ReduxWrapper>
    </>
  )
}

export const wrapPageElementWrapper = ({ element }) => (
  <>
    <GlobalStyle />
    <SiteWrapper>
      <ModalProvider backgroundComponent={FadingBackground}>
        {element}
      </ModalProvider>
    </SiteWrapper>
  </>
)

export const WrapLayout = ({ element }) => (
  <>
    <ReduxWrapper>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <SiteWrapper>
          <ModalProvider backgroundComponent={FadingBackground}>
            {element}
          </ModalProvider>
        </SiteWrapper>
      </ThemeProvider>
    </ReduxWrapper>
  </>
)

export const wrapItem = (Component, props) => (
  <ReduxWrapper>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <SiteWrapper>
        <ModalProvider backgroundComponent={FadingBackground}>
          <Component {...props} />
        </ModalProvider>
      </SiteWrapper>
    </ThemeProvider>
  </ReduxWrapper>
)
