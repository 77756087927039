import React, { useEffect, useState } from "react"
import { Flex, Box, Button } from "rebass/styled-components"
import IconPlan from "./icons/IconPlan"

const drupalMessage = {
  "Not Acceptable : Account is temporarily blocked.":
    "Le compte est temporairement bloqué, veuiller contacter l'administrateur.",
  "Unauthorized : Wrong username or password.":
    "Mot de passe ou nom d'utilisateur erroné.",
}

const Message = ({ message, status, onClose, children, ...props }) => {
  const [open, setOpen] = useState(true)

  useEffect(() => {
    if (!open) {
      if (typeof onClose === "function") {
        onClose()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  status = status || "error"
  const colorsStatus = {
    error: "danger",
    warning: "warning",
    success: "success",
  }
  if (typeof children === "string") {
    if (drupalMessage[children]) {
      children = drupalMessage[children]
    }
  }

  if (!open) {
    return <></>
  }
  return (
    <Flex
      p={2}
      alignItems="flex-start"
      justifyContent="space-between"
      width="100%"
      bg={colorsStatus[status]}
      color="white"
      sx={{ borderRadius: 6 }}
      {...props}
    >
      <Box py={2}>{children}</Box>
      <Button variant="ninja" onClick={() => setOpen(false)}>
        <IconPlan icon="close" size="24" sx={{ minWidth: "24px" }} />
      </Button>
    </Flex>
  )
}
export default Message
