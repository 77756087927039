import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import { getValue, createMarker } from "../../../utils"
import { prepareGeoJson } from "./geoJson"
import axios from "axios"
import * as turf from "@turf/turf"
import { Flex, Heading, Text } from "rebass/styled-components"
import { connect } from "react-redux"
import { setCurrentRouting } from "../../../redux/actions/currentNodeActions"
import ModalCustom from "../Modal"
import Loader from "../Loader"
import "maplibre-gl/dist/maplibre-gl.css"
import useGeolocation from "../../../utils/geo"
import { setUserPosition } from "../../../redux/actions/globalAction"

function getSpeed(positions) {
  if (positions.length < 2) {
    return 1
  }
  var from = turf.point(positions[1].coords)
  var to = turf.point(positions[0].coords)

  var distance = turf.distance(from, to)
  var timeDif = positions[0].timestamp - positions[1].timestamp
  return ((distance * 1000) / timeDif) * 3.6
}

function getNextPos(current, routeCoords, speed) {
  speed = speed || 10
  var line = turf.lineString(routeCoords)
  var start = turf.point(current)
  var stop = turf.point(routeCoords[routeCoords.length - 1])
  var sliced = turf.lineSlice(start, stop, line)
  var along = turf.along(sliced, speed / 1000)
  return along
}
function getZoom(speed) {
  var zoom = 17
  if (speed < 100) {
    zoom = 22 - 4 * (speed / 100)
  }
  // console.log(Math.round((zoom + Number.EPSILON) * 100) / 100, "zoom")
  return Math.round((zoom + Number.EPSILON) * 100) / 100
}

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  #instructions {
    position: absolute;
    width: 300px;
    top: 0;
    right: 0;
    height: 100%;
    padding: 10px;
    background: #ffffff;
  }
  #map-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    .map {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
  .maplibregl-popup-content {
    padding: 10px;
    text-align: center;
  }
  .debug-info {
    position: absolute;
    bottom: 0;
    right: 0;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  .maplibregl-ctrl-bottom-left,
  .maplibregl-ctrl-bottom-right,
  .maplibregl-ctrl-top-left,
  .maplibregl-ctrl-top-right {
    z-index: 1;
  }
`

const currentPoiLayer = {
  id: "poi",
  type: "symbol",
  source: "current-poi",
  layout: {
    "icon-allow-overlap": true,
    "icon-image": [
      "coalesce",
      ["image", ["get", "icon"]],
      ["image", "gratuit"],
    ],
    "icon-size": 0.9,
    "icon-anchor": "bottom",
  },
}

const userPosLayer = {
  id: "user",
  type: "symbol",
  source: "user-position",
  layout: {
    "icon-allow-overlap": true,
    "icon-image": ["image", "nav"],
    "icon-size": 0.9,
    "icon-pitch-alignment": "map",
    "icon-anchor": "top",
    "icon-rotate": ["get", "bearing"],
    "icon-rotation-alignment": "map",
  },
}
const routeLayer = {
  id: "route",
  type: "line",
  source: "route",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-width": 10,
    "line-opacity": 1,
    "line-color": "#3b9dcb",
  },
}

const NavigationMap = ({ node, isOpen, userPosition, dispatch, onArrive }) => {
  const geolocation = useGeolocation({
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: 27000,
  })
  const lat = -22.27054993226
  const lon = 166.44123627113
  // const { allLesEntreprisesJson } = useStaticQuery(
  //   graphql`
  //     query {
  //       allLesEntreprisesJson(
  //         filter: {
  //           field_poi_enable: { und: { elemMatch: { value: { eq: "true" } } } }
  //           field_geofield: {
  //              lat: { ne: null }
  //                 lon: { ne: null }
  //           }
  //         }
  //       ) {
  //         nodes {
  //           title
  //           drupal_internal__nid
  //           field_geofield {
  //   lat
  //   lon
  // }
  //         }
  //       }
  //     }
  //   `
  // )
  // const reperes = allLesEntreprisesJson.nodes
  //   .filter(nodeItem => nodeItem.drupal_internal__nid !== node.nid)
  //   .map(nodeItem => ({
  //     center: [
  //       nodeItem.field_geofield.lon,
  //       nodeItem.field_geofield.lat,
  //     ],
  //     title: nodeItem.title,
  //   }))
  const reperes = []
  const zoom = 19
  const mapOptions = {}
  const containerRef = useRef()
  const [map, setMap] = useState(false)
  const [mapIsCentered, setMapIsCentered] = useState(true)
  const [openArriveModal, setOpenArriveModal] = useState(false)
  // const [firstTimestamp, setFirstTimestamp] = useState(false);
  const [currentPopup, setCurrentPopup] = useState(false)
  const [userStickedPosition, setUserStickedPosition] = useState(false)
  const [route, setRoute] = useState(false)
  // const [maneuvers, setManeuvers] = useState([])
  const [lastsPostions, setLastsPostions] = useState([])
  const [poiCoords, setPoiCoords] = useState([
    parseFloat(getValue(node, "field_geofield", "lon")),
    parseFloat(getValue(node, "field_geofield", "lat")),
  ])
  useEffect(() => {
    if (isOpen) {
      setLastsPostions([])
      setRoute(false)
      dispatch(setCurrentRouting(false))
      setUserStickedPosition(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    if (
      (geolocation.longitude !== userPosition[0] ||
        geolocation.latitude !== userPosition[1]) &&
      !geolocation.error &&
      !geolocation.loading
    ) {
      // var oldPos = turf.point(userPosition)
      // var newPos = turf.point([geolocation.longitude, geolocation.latitude])
      // console.log(userPosition, "userPosition")
      // console.log(
      //   [geolocation.longitude, geolocation.latitude],
      //   "[geolocation.longitude, geolocation.latitude]"
      // )
      // console.log(
      //   turf.distance(oldPos, newPos),
      //   "turf.distance(oldPos, newPos)"
      // )
      // if (turf.distance(oldPos, newPos) > 0.02)
      dispatch(setUserPosition([geolocation.longitude, geolocation.latitude]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geolocation])

  useEffect(() => {
    if (node) {
      setPoiCoords([
        parseFloat(getValue(node, "field_geofield", "lon")),
        parseFloat(getValue(node, "field_geofield", "lat")),
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node])

  useEffect(() => {
    const maplibregl = require("maplibre-gl")
    const mapSettings = {
      container: containerRef.current,
      center: [lon, lat],
      offset: [0, -90], //h,v
      style: "https://map.serveur.nc/styles/plannc/style.json",
      zoom: zoom,
      minZoom: 8,
      maxZoom: 19,
      scrollZoom: true,
      interactive: true,
      // pitch: 60,
      ...mapOptions,
    }
    const map = new maplibregl.Map(mapSettings)
    map.addControl(
      new maplibregl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      }),
      "top-left"
    )
    // map.dragRotate.disable();
    // disable map rotation using touch rotation gesture
    // map.touchZoomRotate.disableRotation();
    const markersIcons = [
      "gratuit",
      "group",
      "gold",
      "premium",
      "lieux",
      "place",
      "street",
      "user",
      "nav",
    ]
    map.on("touchmove", () => {
      setMapIsCentered(false)
    })
    for (var i = 0; i < markersIcons.length; i++) {
      const imageId = markersIcons[i]
      map.loadImage("/markers/" + imageId + ".png", function (error, image) {
        if (error) throw error
        if (!map.hasImage(imageId)) map.addImage(imageId, image)
      })
    }
    if (process.env.NODE_ENV === "development") {
      map.on("click", function (e) {
        dispatch(setUserPosition([e.lngLat.lng, e.lngLat.lat]))
      })
    }
    map.on("load", () => {
      map.addSource("current-poi", {
        type: "geojson",
        data: prepareGeoJson([createMarker(node, true)]),
        cluster: false,
      })
      map.addSource("user-position", {
        type: "geojson",
        data: prepareGeoJson([{ center: userPosition }]),
        cluster: false,
      })
      map.addSource("next-position", {
        type: "geojson",
        data: prepareGeoJson([{ center: userPosition }]),
        cluster: false,
      })
      map.addSource("route", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
        cluster: false,
      })

      map.addLayer(routeLayer)
      map.addLayer(currentPoiLayer)
      map.addLayer(userPosLayer)
      const reperesLayer = {
        id: "reperes",
        type: "symbol",
        source: "reperes",
        minzoom: 17,
        layout: {
          // Set the label content to the
          // feature's `name` property
          "text-field": ["get", "title"],
          "text-font": ["Open Sans Regular"],
          "text-size": 11,
        },
        paint: {
          "text-color": "#000",
          "text-halo-blur": 1,
          "text-halo-color": "#fff",
          "text-halo-width": 1,
        },
      }
      map.addSource("reperes", {
        type: "geojson",
        data: prepareGeoJson(reperes, true),
      })
      map.addLayer(reperesLayer)
      setMap(map)
      map.resize()
    })
    return function cleanup() {
      if (map) {
        map.remove()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (route && userPosition && isOpen) {
      var line = turf.lineString(route.coordinates)
      // console.log(line)
      var pt = turf.point(userPosition)
      var snapped = turf.nearestPointOnLine(line, pt)
      var distance = turf.distance(pt, snapped)
      var poiPoint = turf.point(poiCoords)

      // console.log(turf.distance(pt, poiPoint), "distance user/poi")
      if (userStickedPosition) {
        const distanceSnapped = turf.distance(
          turf.point(userStickedPosition),
          snapped
        )
        if (distanceSnapped < 0.02) {
          return
        }
      }

      if (distance > 0.03) {
        // calculateTraject()
        // return
      }
      if (!openArriveModal) {
        setOpenArriveModal(
          turf.distance(pt, poiPoint) < 0.019 && !openArriveModal
        )
      }
      const newPosition =
        distance > 0.03 ? userPosition : snapped.geometry.coordinates
      if (
        turf.distance(turf.point(line.geometry.coordinates[0]), snapped) > 0.03
      ) {
        // calculateTraject()
        // return
      }

      const newLastPositions =
        lastsPostions.length > 0
          ? [
              {
                timestamp: Date.now() / 1000,
                coords: newPosition,
              },
              lastsPostions[0],
            ]
          : [
              {
                timestamp: Date.now() / 1000,
                coords: newPosition,
              },
            ]

      setLastsPostions(newLastPositions)

      setUserStickedPosition(snapped.geometry.coordinates)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPosition, route])

  useEffect(() => {
    if (map && userStickedPosition && isOpen) {
      map
        .getSource("user-position")
        .setData(prepareGeoJson([{ center: userStickedPosition }]))
      // console.log(prepareGeoJson([{ center: userStickedPosition }]), "position")

      if (route) {
        const bearing = turf.bearing(
          getNextPos(userStickedPosition, route.coordinates, 1),
          getNextPos(userStickedPosition, route.coordinates)
        )
        map.getSource("user-position").setData(
          prepareGeoJson([
            {
              center: userStickedPosition,
              bearing,
            },
          ])
        )
        // console.log(userStickedPosition, "userStickedPosition")
        // console.log(lastsPostions, "lastsPostions")
        const speed = getSpeed(lastsPostions)
        // console.log(speed, "speed")

        if (mapIsCentered) {
          var newZoom = getZoom(speed)

          map.easeTo({
            center: userStickedPosition,
            bearing,
            pitch: 60 * ((22 - newZoom) / 5),
            zoom: newZoom,
            duration: 1000,
            easing: function (v) {
              return v
            },
            offset: [0, containerRef.current.clientHeight / 4],
          })
        }
      } else {
        if (mapIsCentered) {
          map.flyTo({
            duration: 1000,
            center: userStickedPosition,
            offset: [0, containerRef.current.clientHeight / 4],
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStickedPosition, route, mapIsCentered])

  useEffect(() => {
    if (map) {
      map.getSource("route").setData({
        type: "FeatureCollection",
        features: route
          ? [
              {
                type: "Feature",
                geometry: route,
              },
            ]
          : [],
      })
      // setFirstTimestamp(Math.floor(Date.now() / 1000));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route])
  const calculateTraject = () => {
    if (userPosition && poiCoords && isOpen) {
      var raw = {
        vehicles: [{ id: 1, start: userPosition, capacity: [4] }],
        options: { g: true },
        jobs: [
          {
            id: 1,
            service: 300,
            amount: [1],
            location: poiCoords,
          },
        ],
      }
      // const start =
      //   lastsPostions.length > 0 ? lastsPostions[0].coords.join(",") + ";" : ""

      const start = ""
      const url =
        "https://routing.serveur.nc/route/v1/driving/" +
        start +
        userPosition.join(",") +
        ";" +
        raw.jobs[0].location.join(",") +
        "?steps=true&overview=full"

      axios.get(url).then((res) => {
        var polyline = require("@mapbox/polyline")
        var geo = polyline.toGeoJSON(res.data.routes[0].geometry)
        setRoute(geo)
        dispatch(setCurrentRouting(res.data.routes[0]))

        // axios
        //   .get(
        //     "https://routing.serveur.nc/match/v1/driving/" +
        //       geo.coordinates
        //         .map(coord => coord[0] + "," + coord[1])
        //         .join(";") +
        //       "?steps=true&geometries=geojson&overview=full&annotations=true"
        //   )
        //   .then(res => {
        //     setRoute(res.data.matchings[0].geometry)
        //     dispatch(setCurrentRouting(res.data.matchings[0]))
        //     const newManeuvers = []

        //     res.data.matchings[0].legs.forEach(leg => {
        //       leg.steps.forEach(step => {
        //         newManeuvers.push(step.maneuver)
        //       })
        //     })
        //     // console.log(newManeuvers)
        //     // setManeuvers(newManeuvers)
        //   })
      })
      if (map) {
        map.resize()
        if (map.getSource("current-poi")) {
          map
            .getSource("current-poi")
            .setData(prepareGeoJson([createMarker(node, true)]))
          if (!currentPopup) {
            const maplibregl = require("maplibre-gl")
            var popup = new maplibregl.Popup({
              closeButton: false,
              closeOnClick: false,
              anchor: "bottom",
              offset: 45,
            })
            popup.setLngLat(poiCoords).setHTML(node.title).addTo(map)
            setCurrentPopup(popup)
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }
  useEffect(calculateTraject, [node, map])

  if (geolocation.error) {
    return (
      <MapWrapper>
        <Flex
          sx={{
            position: "absolute",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            // width: "100%",
            // height: "100%",
            zIndex: 2,
            display: "flex",
            alignItems: "center",
            background: "rgba(256,256,256,0.7)",
          }}
        >
          <Text display="block" textAlign="center" width={1}>
            {geolocation.error.code === 1
              ? "Veuillez activer et accepter l'utilisation du GPS."
              : geolocation.error.message}
          </Text>
        </Flex>
      </MapWrapper>
    )
  }
  return (
    <>
      <MapWrapper>
        {!route && (
          <Flex
            sx={{
              position: "absolute",
              bottom: 0,
              top: 0,
              left: 0,
              right: 0,
              // width: "100%",
              // height: "100%",
              zIndex: 2,
              display: "flex",
              alignItems: "center",
              background: "rgba(256,256,256,0.7)",
            }}
          >
            <Loader message="Recherche de l'itineraire en cours" />
          </Flex>
        )}

        <div id="map-wrapper">
          <div ref={containerRef} className="map" />
        </div>
        {/* <div id="instructions"></div> */}
        <Text
          p={3}
          backgroundColor="white"
          sx={{
            boxShadow: "tooltip",
            transition: "all 0.4s cubic-bezier(1,.06,.51,1.3) 0.3s",
            position: "absolute",
            bottom: 100,
            zIndex: 1,
            opacity: mapIsCentered && userPosition !== false ? 0 : 1,
            right: mapIsCentered && userPosition !== false ? -180 : 2,
            borderRadius: 20,
            backgroundColor: "white !important",
            // lineHeight: 0,
            circle: {
              fill:
                mapIsCentered && userPosition !== false
                  ? "danger"
                  : "currentColor",
            },
          }}
          onClick={() => {
            setMapIsCentered(true)
          }}
        >
          Recentrer
          <br />
          la carte
        </Text>
        {/* <Button
          sx={{
            boxShadow: "tooltip",
            transition: "all 0.4s cubic-bezier(1,.06,.51,1.3) 0.2s",
            opacity: mapIsCentered && userPosition !== false ? 0 : 1,
            position: "absolute",
            bottom: 100,
            zIndex: 100,
            right: mapIsCentered && userPosition !== false ? -100 : 0,
            borderRadius: 999,
            backgroundColor: "white !important",
            lineHeight: 0,
            circle: {
              fill:
                mapIsCentered && userPosition !== false
                  ? "danger"
                  : "currentColor",
            },
          }}
          color="text"
          onClick={() => {
            setMapIsCentered(true)
          }}
          m={2}
          p={1}
        >
          <svg
            viewBox="0 0 500 500"
            width="30"
            height="30"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M 285.151 0 L 285.151 66 C 360.35 80.324 419.7 139.649 434.025 214.851 L 434.025 214.851 L 500 214.851 L 500 285.175 L 434.025 285.175 C 419.7 360.374 360.35 419.699 285.151 434.025 L 285.151 434.025 L 285.151 500 L 214.851 500 L 214.851 434.025 C 139.651 419.7 80.3 360.376 65.975 285.175 L 65.975 285.175 L 0 285.175 L 0 214.851 L 65.975 214.851 C 80.3 139.651 139.651 80.327 214.851 66 L 214.851 66 L 214.851 0 L 285.151 0 Z M 250.001 119.861 C 178.252 119.861 119.834 178.249 119.834 249.999 C 119.834 321.777 178.252 380.166 250.001 380.166 C 321.751 380.166 380.167 321.777 380.167 249.999 C 380.167 178.249 321.751 119.861 250.001 119.861 Z M 250.001 172.974 C 292.556 172.974 327.029 207.474 327.029 250.001 C 327.029 292.556 292.556 327.057 250.001 327.057 C 207.445 327.057 172.974 292.556 172.974 250.001 C 172.974 207.474 207.445 172.974 250.001 172.974 Z"
              fill="currentColor"
            />
            <circle
              // fill={mapIsCentered ? "rgb(247, 0, 0)" : "currentColor"}
              cx="249.999"
              cy="250.001"
              r="79.59"
            />
          </svg>
        </Button> */}
      </MapWrapper>

      <ModalCustom
        isOpen={openArriveModal}
        setIsOpen={(op) => {
          setOpenArriveModal(op)
          if (!op) {
            if (typeof onArrive === "function") {
              onArrive(false)
            }
          }
        }}
        // title={`Vous êtes bien arrivé a ${node.title}`}
      >
        <Flex alignItems="center" justifyContent="center" p={4}>
          <Heading as="h3">Vous êtes bien arrivé a {node.title}</Heading>
        </Flex>
      </ModalCustom>
    </>
  )
}

const mapStateToProps = (state) => ({
  currentNode: state.currentNode.item,
  routing: state.currentNode.routing,
  userPosition: state.global.userPosition,
})

export default connect(mapStateToProps)(NavigationMap)
