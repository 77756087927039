import React, { useState } from "react"
import { Flex, Button, Box, Text } from "rebass/styled-components"
import { connect } from "react-redux"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import IconPlan from "../icons/IconPlan"
import IconCategorie from "../icons/IconCategorie"
const Categories = ({ isMap }) => {
  const [isOpen, setIsOpen] = useState(false)
  const data = useStaticQuery(graphql`
    query MainCategories {
      allTaxonomyTermCat(sort: { name: ASC }) {
        nodes {
          name
          path {
            alias
          }
          relationships {
            parent {
              name
            }
          }
          field_icon
        }
      }
    }
  `)

const firstCategories = data.allTaxonomyTermCat.nodes.filter((categorie, i) => {
if(categorie?.relationships?.parent.length>0){
	return false;
}
return true
})

// const firstCategories =[];
  return (
    <Box>
      <Button variant="ninja" width={1} onClick={() => setIsOpen(!isOpen)}>
        <Flex
          alignItems="center"
          pb={3}
          pt={isMap ? 3 : 0}
          width={1}
          bg="white"
        >
          <IconPlan
            icon={isOpen ? "grid_fill" : "grid_outline"}
            color="primary"
            size="24"
          />
          <Text pl={3} display={"flex"}>
            Catégories
          </Text>
        </Flex>
      </Button>
      <Box
        sx={{
          transition: "all 300ms ease",
          borderStyle: "solid",
          borderWidth: "0",
          borderTopWidth: "1px",
          borderColor: "gray30",
        }}
      >
        <Box
          py={isOpen ? 3 : 0}
          opacity={!isOpen ? 0 : 1}
          height={!isOpen ? 0 : "100%"}
          sx={{
            transition: !isOpen ? "all 300ms ease" : "all 300ms ease 300ms",
            overflow: !isOpen ? "hidden" : "auto",
          }}
        >
          <Flex alignItems="baseline" flexWrap="wrap" ml={-1}>
            {firstCategories
              .map((categorie, i) => (
                <Box
                  px={1}
                  width={isMap ? [1, 1 / 2] : [1, 1 / 2, 1 / 2, 1 / 4]}
                  key={i}
                  sx={{
                    borderRight: !isMap
                      ? [
                          "",
                          i % 2 === 0 ? "1px solid rgba(160,169,186,0.3)" : "",
                          i % 2 === 0 ? "1px solid rgba(160,169,186,0.3)" : "",
                          i % 4 !== 0 && i % 4 !== 3
                            ? "1px solid rgba(160,169,186,0.3)"
                            : "",
                        ]
                      : i % 2 === 0
                      ? ["", "1px solid rgba(160,169,186,0.3)"]
                      : "",
                  }}
                >
                  <Link to={categorie?.path.alias} state={{ isMap: isMap }}>
                    <Button
                      variant="ninja"
                      width={1}
                      py={1}
                      sx={{
                        "&:hover": {
                          backgroundColor: "grayLight",
                          borderRadius: "4px",
                        },
                      }}
                    >
                      <Flex alignItems="center" py={2} px={2} width={1}>
                        <Flex alignItems="center" color="primary">
                          <IconCategorie
                            icon={categorie.field_icon || ""}
                            size="20"
                          />
                        </Flex>
                        <Text
                          textAlign="left"
                          pl={2}
                          fontSize="14rem"
                          width="100%"
                          sx={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {categorie.name}
                        </Text>
                      </Flex>
                    </Button>
                  </Link>
                </Box>
              ))}
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}
const mapStateToProps = state => ({
  isMap: state.global.isMap,
})

export default connect(mapStateToProps)(Categories)
