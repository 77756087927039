import React, { useState, useEffect } from "react"
import { getValue, capitalize } from "../../utils"
import { connect } from "react-redux"
import { Link } from "gatsby"
import ModalCustom from "../UI/Modal"
import { Box, Text, Heading, Flex, Button } from "rebass/styled-components"
import HTMLReactParser from "html-react-parser"
// import Address from "../UI/poi/Address"
import IconPlan from "../UI/icons/IconPlan"
import moment from "moment"
import "moment/locale/fr"
import { getRdvList } from "../../redux/actions/currentUserActions"
import Loader from "../UI/Loader"
import { axiosInstance } from "../../utils"

const RdvUser = ({ rdv, isPast, token, dispatch }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [adresse, setAdresse] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (loading && !isOpen) {
      setLoading(false)
    }
  }, [isOpen, loading])

  const removeRdv = () => {
    if (rdv) {
      setLoading(true)
      axiosInstance
        .post(
          `/api/v2/delete_rdv`,
          { nid: rdv.nid },
          {
            crossdomain: true,
            headers: {
              "Content-Type": "application/json",
              "X-AUTH-TOKEN": token,
            },
          }
        )
        .then((res) => {
          // console.log(res.data);
          if (res.data.success) {
            setIsOpen(false)
            dispatch(getRdvList())
          } else {
            setIsOpen(false)
          }
        })
    }
  }
  if (rdv) {
    return (
      <Box
        sx={{
          borderColor: isPast ? "transparent" : "text",
          borderWidth: "1px",
          borderStyle: "solid",
          borderRadius: 4,
        }}
        bg={isPast ? "gray10" : ""}
        p={2}
        my={2}
      >
        <Box opacity={isPast ? 0.5 : 1}>
          <Heading as="h1" variant="h3">
            <Link to={rdv.path}>{rdv.company}</Link>
          </Heading>
          <Box my={2}>
            <Text fontWeight="bold">Date, heure et lieu du rendez-vous</Text>
            <Box my={2} height="2px" bg="gray10" />
            <Flex mx={-2}>
              <Flex alignItems="flex-start" width={1 / 2} p={2}>
                <IconPlan
                  icon="calendar_fill"
                  size="24"
                  color={isPast ? "text" : "primary"}
                />
                <Box pl={1}>
                  <Text>
                    {capitalize(
                      moment(rdv.date, "X").format("dddd DD MMMM YYYY")
                    )}
                  </Text>
                  <Text>à {moment(rdv.date, "X").format("HH [h] mm")}</Text>
                </Box>
              </Flex>
              <Flex alignItems="flex-start" width={1 / 2} p={2}>
                {/* <Address node={node} /> */}
                {adresse && (
                  <>
                    <IconPlan
                      icon="map-marker"
                      size="24"
                      color={isPast ? "text" : "primary"}
                    />
                    <Box pl={1}>
                      <Text>{adresse.adresse1}</Text>
                      <Text>{adresse.adresse2}</Text>
                      {adresse.complement && <Text>{adresse.complement}</Text>}
                      {adresse.bp && <Text>{adresse.bp}</Text>}
                    </Box>
                  </>
                )}
              </Flex>
            </Flex>
          </Box>
          <Box py={4}>
            <Text fontWeight="bold">Type de prestation</Text>
            <Box my={2} height="2px" bg="gray10" />
            <Box>
              <Flex justifyContent="space-between">
                <Text>
                  {HTMLReactParser(
                    getValue(rdv, "service", false, "").replace("&#039;", "'")
                  )}
                </Text>
                {getValue(rdv, "prix", false, null) !== null &&
                  getValue(rdv, "prix", false, "") !== "" && (
                    <>
                      {parseInt(getValue(rdv, "prix", false, 0)) > 0 && (
                        <Text fontWeight="bold">
                          {getValue(rdv, "prix", false, "")} F
                        </Text>
                      )}
                    </>
                  )}
              </Flex>
            </Box>
            {getValue(rdv, "body", "value", null) !== null && (
              <Text fontSize="80%" color="primary" pt={1}>
                {HTMLReactParser(getValue(rdv, "body", "value", ""))}
              </Text>
            )}
            <Flex mx={-2} pt={2}>
              {getValue(rdv, "field_rdv_infos") !== false && (
                <Box width={1 / 2} p={2}>
                  <Text fontWeight="bold">Informations complémentaires</Text>
                  <Box my={2} height="2px" bg="gray10" />
                  <Text>
                    {HTMLReactParser(
                      getValue(rdv, "field_rdv_infos", false, "")
                    )}
                  </Text>
                </Box>
              )}
              {getValue(rdv, "field_payment") !== false && (
                <Box width={1 / 2} p={2}>
                  <Text fontWeight="bold">Moyens de paiement</Text>
                  <Box my={2} height="2px" bg="gray10" />
                  <Text>
                    {HTMLReactParser(getValue(rdv, "field_payment", false, ""))}
                  </Text>
                </Box>
              )}
            </Flex>
          </Box>
          {!isPast && (
            <Flex justifyContent="flex-end">
              <Button
                variant="warningGradiant"
                display="flex"
                alignItems="center"
                px={2}
                py={1}
                onClick={() => setIsOpen(true)}
              >
                <IconPlan icon="error_outline" size="17" />
                <Text pl={2}>Annuler</Text>
              </Button>
            </Flex>
          )}
        </Box>
        <ModalCustom
          isOpen={isOpen !== false}
          title="Annulation du rendez-vous"
          setIsOpen={setIsOpen}
        >
          {loading && <Loader message="Annulation en cours" />}
          {!loading && (
            <Box p={4}>
              <Box textAlign="center" pb={4}>
                <Heading>
                  Êtes-vous sur de vouloir annuler le rdv{" "}
                  <Text>
                    "{HTMLReactParser(getValue(rdv, "service", false, ""))}"
                  </Text>
                  <Text>avec {rdv.company}</Text>
                  <Text>
                    le{" "}
                    {capitalize(
                      moment(getValue(rdv, "date", false, ""), "X").format(
                        "dddd DD MMMM YYYY"
                      )
                    )}{" "}
                    à{" "}
                    {moment(getValue(rdv, "date", false, ""), "X").format(
                      "HH [h] mm"
                    )}
                  </Text>
                </Heading>
              </Box>

              <Flex width="100%">
                <Flex width={1 / 2} justifyContent="center">
                  <Button
                    display="flex"
                    variant="successGradiantBig"
                    alignItems="center"
                    onClick={() => removeRdv()}
                  >
                    <IconPlan icon="success_outline" size="24" />
                    <Text pl={2}>Oui</Text>
                  </Button>
                </Flex>
                <Flex width={1 / 2} justifyContent="center">
                  <Button
                    display="flex"
                    variant="dangerGradiantBig"
                    alignItems="center"
                    onClick={() => setIsOpen(false)}
                  >
                    <IconPlan icon="error_outline" size="24" />
                    <Text pl={2}>Non</Text>
                  </Button>
                </Flex>
              </Flex>
            </Box>
          )}
        </ModalCustom>
      </Box>
    )
  }
  return <></>
}

const mapStateToProps = (state) => ({
  token: state.currentUser.token,
})
export default connect(mapStateToProps)(RdvUser)
