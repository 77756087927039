import React from "react"
import Page from "../templates/Page";

const Cookies = ({ location }) => (
  <Page title="Information sur les cookies" location={location} forceMap={false}>
    <h1>Information sur les cookies</h1>
    <div>
      <div>
        <p>Les Cookies sont de petits fichiers texte d&eacute;pos&eacute;s sur votre terminal (ordinateur, tablette ou mobile) lorsque vous naviguez sur des sites internet, et notamment sur www.Plan.nc.</p>
        <h2>Quels Cookies utilisons-nous ?</h2>
        <p>En fonction du terminal que vous utilisez pour naviguer sur l&rsquo;un de nos sites, nous mettons en place des Cookies notamment pour les raisons suivantes :</p>
        <ul>
          <li>Pour nous aider &agrave; reconna&icirc;tre votre navigateur d'une session &agrave; l'autre et pour pour nous souvenir de vos pr&eacute;f&eacute;rences. Cela permet par exemple de faire en sorte que vous n'ayez pas besoin de retaper vos informations de formulaires</li>
          <li>Pour aider &agrave; mesurer de fa&ccedil;on transversale l'activit&eacute; de nos visiteurs sur les sites et nous permettre ainsi de proposer une exp&eacute;rience toujours mieux adapt&eacute;e&nbsp;&agrave; vos&nbsp;attentes.</li>
        </ul>
        <p>Pour plus de d&eacute;tails sur les Cookies utilis&eacute;s sur ce terminal, merci de consulter sur cette page le tableau de la liste des Cookies utilis&eacute;s sur www.Plan.nc.</p>
        <h2>Comment param&eacute;trer les Cookies via mon navigateur internet ?</h2>
        <p>Les param&egrave;tres par d&eacute;faut des navigateurs internet sont habituellement r&eacute;gl&eacute;s de mani&egrave;re &agrave; accepter les Cookies, mais vous pouvez facilement changer ces r&eacute;glages en modifiant les param&egrave;tres de votre navigateur. Toutefois, si vous choisissez de d&eacute;sactiver les Cookies via votre navigateur, il est possible que vous ne puissiez plus b&eacute;n&eacute;ficier de toutes les fonctionnalit&eacute;s que nous proposons &agrave; travers nos sites.</p>
        <p>Pour plus d&rsquo;informations sur le param&eacute;trage des Cookies, vous pourrez consulter les sites suivants:<br /><a href="http://www.cnil.fr/vos-droits/vos-traces/les-cookies/" target="_blank" rel="noopener noreferrer">http://www.cnil.fr/vos-droits/vos-traces/les-cookies/</a> (en Fran&ccedil;ais) ou <a href="http://www.aboutcookies.org/" target="_blank" rel="noopener noreferrer">http://www.aboutcookies.org/</a> en Anglais).<br />Pour Internet Explorer&trade;: <a href="http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies" target="_blank" rel="noopener noreferrer">http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies</a><br />Pour Safari&trade;: <a href="http://support.apple.com/kb/ht1677?viewlocale=fr_FR" target="_blank" rel="noopener noreferrer">http://support.apple.com/kb/ht1677?viewlocale=fr_FR</a>,<br />Pour Chrome&trade;: <a href="https://support.google.com/chrome/answer/95647?hl=fr" target="_blank" rel="noopener noreferrer">https://support.google.com/chrome/answer/95647?hl=fr</a>,<br />Pour Firefox&trade; : <a href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies" target="blank">https://support.mozilla.org/fr/kb/activer-desactiver-cookies</a>,<br />Pour Opera&trade; : <a href="http://help.opera.com/Windows/10.20/fr/cookies.html" target="_blank" rel="noopener noreferrer">http://help.opera.com/Windows/10.20/fr/cookies.html</a>.</p>
        <h2>Accueillons-nous des Cookies de tiers ?</h2>
        <p>Des Cookies de sites internet de tiers sont susceptibles d&rsquo;&ecirc;tre enregistr&eacute;s sur votre terminal et visent &agrave; personnaliser et/ou am&eacute;liorer les contenus (y compris publicitaires) qui peuvent vous &ecirc;tre propos&eacute;s lors de votre navigation. En fonction des supports, plusieurs Cookies de tiers seront ainsi susceptibles d&rsquo;&ecirc;tre enregistr&eacute;s, notamment :</p>
        <p>Contenus R&eacute;seaux Sociaux : nous pouvons &ecirc;tre amen&eacute;s &agrave; publier sur nos supports des contenus &eacute;manant de r&eacute;seaux sociaux. Ainsi, lorsque vous consultez un tel contenu plac&eacute; sur nos supports, un cookie du r&eacute;seau social correspondant peut &ecirc;tre enregistr&eacute; sur votre terminal. Nous vous invitons &agrave; prendre connaissance de la politique de gestion de ces cookies de r&eacute;seaux sociaux sur les sites concern&eacute;s.</p>
        <p>Flash : les cookies flash sont des fichiers stock&eacute;s par le logiciel Flash. Ce logiciel est souvent install&eacute; comme extension des navigateurs internet pour visualiser des objets dynamiques, comme par exemple des animations graphiques ou encore des s&eacute;quences vid&eacute;o. Ainsi des cookies Flash peuvent &ecirc;tre install&eacute;s d&egrave;s l&rsquo;utilisation de l&rsquo;application flash. Nous vous invitons &agrave; prendre connaissance de la politique de gestion des cookies Flash sur le site www.adobe.com/fr/ ou www.adobe.com</p>
        <h2>Pla&ccedil;ons-nous des Cookies sur des supports de tiers ?</h2>
        <p>www.Plan.nc ne d&eacute;pose pas de Cookie sur des supports de tiers.</p>
        <h2>Quels Cookies sont plac&eacute;s sur notre Site ?</h2>
        <p>Au 01/10/2014, les Cookies suivants sont utilis&eacute;s sur ce support <strong>*</strong> :</p>
        <table cellSpacing="5" cellPadding="5">
          <tbody>
            <tr>
              <td><strong>Statut</strong></td>
              <td><strong>Nom du cookie</strong></td>
              <td><strong>Finalit&eacute;</strong></td>
              <td><strong>Nature</strong></td>
              <td><strong>Description</strong></td>
            </tr>
            <tr>
              <td>Actif</td>
              <td>PHPSESSID</td>
              <td>cookie de session</td>
              <td>Fonctionnel (Obligatoire)</td>
              <td>Identification de l&rsquo;internaute lors de sa connexion au site. Expire d&egrave;s la fin de session.</td>
            </tr>
            <tr>
              <td>Actif</td>
              <td>&nbsp;Google Analytics</td>
              <td>Affluence et utilisation du site</td>
              <td>Performance (Optionnel)</td>
              <td>Il est attribu&eacute; &agrave; chaque visiteur d&rsquo;une page internet un identifiant unique via le Cookie __utma. De fait un m&ecirc;me visiteur se verra attribuer le m&ecirc;me identifiant &agrave; chaque visite et ne sera pas comptabilis&eacute; 2 fois.</td>
            </tr>
          </tbody>
        </table>
        <p>Pour m&eacute;moire, en d&eacute;sactivant les Cookies identifi&eacute;s comme optionnels via votre navigateur, vous d&eacute;sactiverez &eacute;galement l&rsquo;ensemble des Cookies utilis&eacute;s sur ce Site, y compris ceux recommand&eacute;s pour le bon fonctionnement de nos sites.</p>
        <p><strong>*</strong> les noms des Cookies utilis&eacute;s sont mis &agrave; jour au moins une fois par an, au 1er janvier de chaque ann&eacute;e.</p>
      </div>
    </div>
  </Page>
)

export default Cookies