/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Box } from "rebass/styled-components"
const MessengerBtn = ({ mb }) => {
  mb = mb || "30px"
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "15px",
        right: "15px",
        background: "#fff",
        display: "block",
        zIndex: "9999",
        borderRadius: "80px",
        boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 10px 0px",
        transition: "all 300ms ease",
        "&:hover": {
          transform: "scale(1.2)",
          bottom: "20px",
        },
        "&:active": {
          transform: "scale(1.1)",
          bottom: "20px",
        },
      }}
      mb={mb}
    >
      <a
        role="button"
        rel="noreferrer"
        title="Contactez-nous sur messenger"
        href="https://m.me/plan.nc"
        target="_blank"
        style={{ display: "block", padding: "10px", paddingBottom: "6px" }}
      >
        <img src="/social/messenger.svg" alt="Logo Messenger" />
      </a>
    </Box>
  )
}

export default MessengerBtn
