import React, { useState } from "react"
import { Box } from "rebass/styled-components"
import Form from "../Form"
import { useStaticQuery, graphql } from "gatsby"
import Loader from "../UI/Loader"
import Message from "../UI/Message"
import { getErrorMessage, getValue, convertDate } from "../../utils"

import { connect } from "react-redux"
import { subscribe, login,getUser } from "../../redux/actions/currentUserActions"

const Inscription = ({ dispatch, autoLoggin }) => {
  autoLoggin = autoLoggin || false
  const [messages, setMessages] = useState([])
  const [currentFormValue, setCurrentFormValue] = useState({})
  const [loading, setLoading] = useState(false)
  const data = useStaticQuery(graphql`
    query LesVilles2 {
      allTaxonomyTermCity(
        filter: { status: { eq: true } }
        sort: { name: ASC }
      ) {
        nodes {
          drupal_id
          drupal_internal__tid
          name
          path {
            alias
          }
        }
      }
    }
  `)

  const villeOptions = data.allTaxonomyTermCity.nodes.map(function (ville) {
    return {
      value: getValue(ville, "drupal_internal__tid"),
      label: getValue(ville, "name"),
    }
  })
  // const villeOptions = {};
  const options = [
    {
      value: "",
      label: "aucun",
    },
    ...villeOptions,
  ]
  const formData = {
    formId: "inscription",
    useCaptcha: false,
    successMessage:
      "Les instructions détaillées de connexion ont été envoyées à votre adresse électronique.",
    submitLabel: "S'inscrire",
    elements: [
      {
        type: "text",
        name: "field_lastname",
        default: currentFormValue.field_lastname
          ? currentFormValue.field_lastname
          : "",
        label: "Nom",
        required: true,
        props: {
          width: [1, 1, 1 / 3],
        },
      },
      {
        type: "text",
        name: "field_firstname",
        default: currentFormValue.field_firstname
          ? currentFormValue.field_firstname
          : "",
        label: "Prénom",
        required: true,
        props: {
          width: [1, 1, 1 / 3],
        },
      },
      {
        type: "textDate",
        name: "field_field_date_birth",
        default: currentFormValue.field_field_date_birth
          ? currentFormValue.field_field_date_birth
          : "",
        label: "Date de naissance",
        required: true,
        props: {
          width: [1, 1, 1 / 3],
        },
      },
      {
        type: "text",
        name: "mail",
        default: currentFormValue.field_lastname ? currentFormValue.mail : "",
        label: "Email",
        required: true,
        props: {
          width: [1, 1, 1 / 2],
        },
      },
      {
        type: "text",
        name: "mail-verif",
        default: currentFormValue?.field_lastname ? currentFormValue.mail : "",
        label: "Vérification email",
        required: true,
        props: {
          width: [1, 1, 1 / 2],
        },
        verification: function (values, data) {
          if (values["mail-verif"] !== values["mail"]) {
            return "les adresses emails ne correspondent pas."
          }
          return false
        },
      },
      {
        type: "text",
        name: "field_phone",
        default: currentFormValue?.field_lastname,
        label: "Téléphone",
        required: true,
        props: {
          width: [1, 1, 1 / 2],
        },
      },
      {
        type: "select",
        name: "field_city",
        default: currentFormValue?.field_city,
        label: "Ville",
        required: true,
        options: options,
        props: {
          width: [1, 1, 1 / 2],
        },
      },
      {
        type: "markup",
        markup: <Box my={2} height="2px" bg="gray10" />,
        props: {
          width: 1,
        },
      },
      {
        type: "password",
        name: "pass",
        default: "",
        label: "Mot de passe",
        required: true,
        passwordmeter: "mail",
        props: {
          width: [1, 1, 1 / 2],
        },
      },
      {
        type: "password",
        name: "pass-verif",
        default: "",
        label: "Confirmer le mot de passe",
        required: true,
        passwordchecker: "pass",
        props: {
          width: [1, 1, 1 / 2],
        },
        verification: function (values, data) {
          if (values["pass-verif"] !== values["pass"]) {
            return "les mots de passe ne correspondent pas."
          }
          return false
        },
      },
      // {
      //   type: "checkbox",
      //   name: 'cgu',
      //   default: currentFormValue.cgu ? parseInt(currentFormValue.cgu) : 0,
      //   label: "",
      //   required: true,
      //   props: {
      //     width: '100%'
      //   },
      //   options: [
      //     {
      //       value: 1,
      //       label: <>J'accepte les <a href='/cgu' target='_blank' rel='noopener noreferrer'><Text color="primary">conditions générales d'utilisation</Text></a>.</>,
      //     }
      //   ]
      // },
      {
        type: "submit",
        label: "S'inscrire",
        props: {
          width: 1,
          display: "flex",
          justifyContent: "center",
        },
        buttonProps: {
          width: [1, 1 / 2],
          variant: "primaryGradiant",
        },
      },
    ],
  }
  const onSubmit = (formValues, formdata, submitSuccess) => {
    setCurrentFormValue({ ...formValues })
    const mail = formValues.mail
    const pass = formValues.pass
    formValues.mail = [{ value: formValues.mail }]
    formValues.field_lastname = [{ value: formValues.field_lastname }]
    formValues.field_firstname = [{ value: formValues.field_firstname }]

    formValues.field_field_date_birth = [
      {
        value:
          convertDate(
            formValues.field_field_date_birth,
            "YYYY-MM-DD",
            "DD/MM/YYYY"
          ) + "T00:00:00+11:00",
      },
    ]
    formValues.field_city = [{ target_id: formValues.field_city }]
    formValues.field_phone = [{ value: formValues.field_phone }]
    formValues.name = formValues.mail
    formValues.pass = [{ value: formValues.pass }]
    delete formValues["pass-verif"]
    delete formValues["mail-verif"]

    // delete formValues['cgu'];
    // formValues.autoLoggin = autoLoggin
    setMessages([])
    setLoading(true)
    dispatch(subscribe(formValues, autoLoggin)).then((action) => {
      setLoading(false)
      if (action.type === "USER_SUBSCRIBE_SUCCESS") {
        if (autoLoggin) {
          setLoading(true)
          dispatch(login({ login: mail, password: pass }, true)).then(
            (action) => {
              if (action.type === "USER_LOGIN_FAIL") {
                setLoading(false)
                setMessages([
                  {
                    status: "error",
                    value: getErrorMessage(action.error.response),
                  },
                ])
              }
              if (action.type === "USER_LOGIN_SUCCESS") {
                dispatch(getUser(action.payload.data.user.uid))
              }
            }
          )
        } else {
          setMessages([
            {
              status: "success",
              value:
                "Les instructions détaillées de connexion ont été envoyées à votre adresse électronique.",
            },
          ])
        }
      }
      if (action.type === "USER_SUBSCRIBE_FAIL") {
        setLoading(false)
        setMessages([
          ...messages,
          { status: "error", value: getErrorMessage(action.error.response) },
        ])
      }
    })
  }
  return (
    <>
      {messages.map((message, key) => (
        <Message
          key={message.value}
          status={message.status}
          mb={2}
          onClose={() =>
            setMessages(messages.filter((value, index) => index !== key))
          }
        >
          {message.value}
        </Message>
      ))}
      {loading && <Loader message="Création du compte en cours" />}
      {!loading && (
        <Form
          data={formData}
          onSubmit={(formValues, data, submitSuccess) => {
            onSubmit(formValues, data, submitSuccess)
          }}
        />
      )}
    </>
  )
}
export default connect()(Inscription)
