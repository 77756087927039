import React, { useState } from "react"
import { Box, Text, Flex, Button } from "rebass/styled-components"
import Block from "../Block"
import { connect } from "react-redux"
import ModalCustom from "../../Modal"
import Webform from "../../../Form/Webform"
import IconPlan from "../../icons/IconPlan"
const Suggestion = ({ node, user }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentValues, setCurrentValues] = useState({})
  return (
    <Box>
      <Block title="Vous avez une suggestion ?">
        <Box py={2}>
          <Button
            mt={3}
            variant="textOutline"
            as="div"
            onClick={() => setIsOpen(true)}
          >
            <Flex alignItems="center">
              <IconPlan
                icon="message_outline-copy"
                sx={{ color: "primary" }}
                size="24"
              />
              <Text pl={2}>Suggérer une modification</Text>
            </Flex>
          </Button>
        </Box>
      </Block>
      <ModalCustom
        isOpen={isOpen !== false}
        setIsOpen={setIsOpen}
        title="Suggestion d'information"
      >
        <Webform
          webformId={"suggestion_d_information"}
          confirmation={"Merci d'avoir répondu au formulaire !\nVotre participation a bien été enregistrée."}
          onChange={(values) => {
            setCurrentValues(values)
          }}
          submitButton={{
            type: "submit",
            label: "Envoyer",
            props: {
              width: 1,
              sx: {
                display:
                  currentValues?.informations_incorrectes1?.length > 0 &&
                  currentValues?.veuillez_preciser
                    ? "block"
                    : "none",
              },
            },
            buttonProps: {
              width: [1, 1 / 2],
              variant: "primaryGradiantBig",
              margin: "0 auto",
              display: "block",
            },
          }}
          formData={{
            elements: [
              {
                props: {
                  sx: {
                    display: "flex",
                    flexWrap: "wrap",
                    ".icon": {
                      color: "primary",
                    },
                    ".checkbox": {
                      width: ["100%", "100%", "calc(25% - 10px)"],
                      padding: "15px",
                      margin: "5px",
                      borderColor: "gray50",
                      borderWidth: "2px",
                      svg: { display: "none" },
                      borderStyle: "solid",
                      borderRadius: "4px",
                      cursor: "pointer",
                      "&.checked": {
                        svg: { display: "none" },
                        ".icon": {
                          color: "white",
                        },
                        backgroundColor: "primary",
                        color: "white",
                        borderColor: "primary",
                      },
                    },
                  },
                },
              },
              {
                props: {
                  display:
                    Array.isArray(currentValues?.informations_incorrectes1) &&
                    currentValues?.informations_incorrectes1.length > 0
                      ? "block"
                      : "none",
                },
              },
              {
                default: node?.title + " (" + node?.drupal_internal__nid + ")",
              },
            ],
          }}
        />
      </ModalCustom>
    </Box>
  )
}
const mapStateToProps = (state) => ({
  user: state.currentUser.user,
})
export default connect(mapStateToProps)(Suggestion)
