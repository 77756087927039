import React from "react"
import { Text } from "rebass/styled-components"
const capitalize = s => {
  if (typeof s !== "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}
const Categorie = ({ name, ...props }) => (
  <Text
    color="text"
    sx={{
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    }}
    {...props}
  >
    {capitalize(name)}
  </Text>
)
export default Categorie
