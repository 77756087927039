import colors from "./colors"
const bigProps = {
  fontSize: "14px",
  px: 6,
  py: 3,
}

const buttons = {
  primary: {
    fontSize: 1,
    fontWeight: "normal",
    color: "background",
    backgroundColor: "primary",
    borderRadius: "default",
    cursor: "pointer",
    borderColor: "transparent",
    borderWidth: "1px",
    borderStyle: "solid",
    transition: "all 300ms ease",
    "&:hover": { backgroundColor: "primaryLight" },
  },
  primaryOutline: {
    variant: "buttons.primary",
    bg: "transparent",
    color: "primary",
    borderColor: "primary",
    borderWidth: "1px",
    borderStyle: "solid",
    "&:hover": { color: "primaryLight", bg: "transparent" },
  },
  textPlain: {
    variant: "buttons.primary",
    bg: "text",
    color: "white",
    borderColor: "text",
    borderWidth: "1px",
    borderStyle: "solid",
    "&:hover": {
      color: "white",
      bg: "gray",
      borderColor: "gray",
    },
  },
  textPlainActive: {
    variant: "buttons.primary",
    bg: "text",
    color: "white",
    borderColor: "text",
    borderWidth: "1px",
    borderStyle: "solid",
    "&:hover": {
      color: "white",
      bg: "text",
      borderColor: "text",
    },
  },
  textOutline: {
    variant: "buttons.primary",
    bg: "transparent",
    color: "text",
    borderColor: "text",
    borderWidth: "1px",
    borderStyle: "solid",
    "&:hover": {
      color: "gray",
      bg: "transparent",
      borderColor: "gray",
    },
  },
  primaryBig: {
    variant: "buttons.primary",
    ...bigProps,
  },
  primaryGradiant: {
    variant: "buttons.buttonGradiant",
    backgroundColor: "primary",
    backgroundImage: colors.gradiantPrimaryButton,
  },
  primaryGradiantBig: {
    variant: "buttons.primaryGradiant",
    ...bigProps,
  },
  buttonGradiant: {
    variant: "buttons.primary",
    fontSize: "14px",
    backgroundSize: "200% 200%",
    backgroundPosition: "left 50%",
    backgroundRepeat: "repeat-x",
    "&:hover": { backgroundPosition: "right 50%" },
  },
  categorieToggle: {
    variant: "buttons.primary",
    bg: "background",
    color: "primary",
    border: "1px solid",
    px: 6,
    borderColor: "gray30",
    "&:hover": { color: "primaryLight" },
  },
  muted: {
    variant: "buttons.primary",
    bg: "gray",
    "&:hover": { bg: "gray50" },
  },
  disabled: {
    variant: "buttons.primary",
    bg: "gray50",
    cursor: "not-allowed",
    "&:hover": { bg: "gray50" },
  },

  goldenGradiant: {
    variant: "buttons.buttonGradiant",
    backgroundColor: "golden",
    backgroundImage: colors.gradiantGoldButton,
  },
  goldenGradiantBig: {
    variant: "buttons.goldenGradiant",
    ...bigProps,
  },
  purpleGradiant: {
    variant: "buttons.buttonGradiant",
    backgroundColor: "purple",
    backgroundImage: colors.gradiantPurpleButton,
  },
  purpleGradiantBig: {
    variant: "buttons.purpleGradiant",
    ...bigProps,
  },
  store: {
    variant: "buttons.primary",
    bg: "black",
    py: 1,
    "&:hover": { bg: "#444444" },
  },
  outline: {
    variant: "buttons.primary",
    color: "primary",
    bg: "transparent",
    borderColor: "primary",
    border: "1px solid",
    "&:hover": { bg: "transparent" },
  },
  topAction: {
    variant: "buttons.simple",
    fontSize: "14px",
    py: 3,
    px: [2, 3],
  },
  mainAction: {
    variant: "buttons.purpleGradiant",
    py: 3,
    px: 2,
  },
  mainActionpremium: {
    variant: "buttons.purpleGradiant",
    py: 3,
    px: [2, 3],
  },
  mainActiongratuit: {
    variant: "buttons.primaryGradiant",
    py: 3,
    px: [2, 3],
  },
  mainActiongold: {
    variant: "buttons.goldenGradiant",
    py: 3,
    px: [2, 3],
  },
  mainActionblue: {
    variant: "buttons.blueGradiant",
    py: 3,
    px: [2, 3],
  },
  simple: {
    variant: "buttons.primary",
    color: "text",
    bg: "transparent",
    borderColor: "muted",
    borderWidth: "1px",
    borderStyle: "solid",
    "&:hover": { bg: "muted" },
  },
  secondary: {
    variant: "buttons.primary",
    color: "background",
    bg: "secondary",
  },
  transparent: {
    variant: "buttons.primary",
    transition: "all 300ms ease",
    color: "inherit",
    bg: "transparent",
    "&:hover": { bg: "transparent" },
  },
  white: {
    variant: "buttons.primary",
    transition: "all 300ms ease",
    color: "primary",
    bg: "white",
    "&:hover": { bg: "primary", color: "white" },
  },
  ninja: {
    cursor: "pointer",
    color: "inherit",
    bg: "transparent",
    borderRadius: 0,
    m: 0,
    p: 0,
    border: "none",
    "&:hover": {
      bg: "transparent",
      opacity: 1,
    },
  },
  suggestions: {
    cursor: "pointer",
    color: "inherit",
    bg: "transparent",
    borderRadius: 0,
    m: 0,
    p: 2,
    border: "none",
    width: "100%",
    textAlign: "left",
    "&:hover": {
      bg: "muted",
      opacity: 1,
    },
  },
  footerLink: {
    variant: "buttons.ninja",
    height: "1em",
    px: 2,
    borderLeft: "1px solid #979797",
    textAlign: ["center", "center", "left"],
  },
}

const buttonColors = [
  "success",
  "danger",
  "warning",
  "purple",
  "golden",
  "text",
  "blue",
]

for (var i = 0; i < buttonColors.length; i++) {
  let buttonColor = buttonColors[i]
  let gradiantColor =
    colors[buttonColor + "Gradiant"] || colors[buttonColor + "Hover"]
  buttons[buttonColor] = {
    variant: "buttons.primary",
    backgroundColor: buttonColor,
    "&:hover": {
      bg: buttonColor + "Hover",
    },
  }

  buttons[buttonColor + "Big"] = {
    ...buttons[buttonColor],
    ...bigProps,
  }
  buttons[buttonColor + "Link"] = {
    ...buttons[buttonColor],
    backgroundColor: "transparent",
    borderWidth: "0",
    borderColor: buttonColor,
    color: buttonColor,
    "&:hover": {
      bg: "transparent",
      borderColor: buttonColor + "Hover",
      color: buttonColor + "Hover",
    },
  }
  buttons[buttonColor + "Outline"] = {
    ...buttons[buttonColor],
    backgroundColor: "transparent",
    borderWidth: "1px",
    borderColor: buttonColor,
    color: buttonColor,
    "&:hover": {
      bg: "transparent",
      borderColor: buttonColor + "Hover",
      color: buttonColor + "Hover",
    },
  }
  buttons[buttonColor + "Gradiant"] = {
    variant: "buttons.buttonGradiant",
    backgroundColor: buttonColor,
    backgroundImage:
      "linear-gradient(90deg, " +
      gradiantColor +
      " , " +
      colors[buttonColor] +
      " 50%," +
      gradiantColor +
      ");",
  }
  buttons[buttonColor + "GradiantBig"] = {
    ...buttons[buttonColor + "Gradiant"],
    ...bigProps,
  }
}

export default buttons
