import React from "react"
import { Box, Flex } from "rebass/styled-components"
const SquareBox = props => {
  return (
    <Box variant="squareBox" {...props}>
      <Flex
        sx={{ position: "absolute" }}
        alignItems="center"
        width="100%"
        height="100%"
      >
        {props.children}
      </Flex>
    </Box>
  )
}

export default SquareBox
