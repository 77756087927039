import React, { useState } from "react"
import { Flex, Box } from "rebass/styled-components"
import Connexion from "./Connect"
import Inscription from "./Inscription"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { connect } from "react-redux"
import { getValue } from "../../utils"
import CodeValidation from "./CodeValidation"
import PhoneUpdate from "./PhoneUpdate"

const ToggleButton = ({ active, label, onClick }) => (
  <Flex
    bg={active ? "white" : "grayLight"}
    color={active ? "primary" : "text"}
    onClick={() => onClick()}
    width={1 / 2}
    sx={{
      cursor: "pointer",
      transition: "all 300ms ease",
      boxShadow: active ? "headerButton" : "none",
    }}
  >
    <Box p={3} py={4} textAlign="center" width="100%" fontWeight="bold">
      {label}
    </Box>
  </Flex>
)

const User = ({ user, autoLoggin }) => {
  autoLoggin = autoLoggin || false
  const [tab, setTab] = useState(0)
  if (user) {
    if (
      getValue(user, "field_phone", "0.value", "") !== "" &&
      getValue(user, "field_phone_check", "0.value", false)
    ) {
      return <></>
    }
    if (getValue(user, "field_phone", "0.value", "") === "") {
      return <PhoneUpdate />
    }
    if (
      getValue(user, "field_phone", "0.value", "") !== "" &&
      !getValue(user, "field_phone_check", "0.value", false)
    ) {
      return <CodeValidation />
    }
  }
  return (
    <>
      <Flex py={2}>
        <ToggleButton
          active={tab === 0}
          label="Connexion"
          onClick={() => setTab(0)}
        />
        <ToggleButton
          active={tab === 1}
          label="Créer un compte"
          onClick={() => setTab(1)}
        />
      </Flex>
      <Box sx={{ transition: "height 300ms ease" }}>
        {tab === 0 && <Connexion />}
        {tab === 1 && <Inscription autoLoggin={autoLoggin} />}
      </Box>
    </>
  )
}
const mapStateToProps = state => ({
  user: state.currentUser.user,
})
export default connect(mapStateToProps)(User)
