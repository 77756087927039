import React from "react"
import { Text, Box, Button, Flex } from "rebass/styled-components"
import { connect } from "react-redux"
import {
  fetchSolr,
  solrResetFilters,
  solrResetItems,
} from "../../../redux/actions/SolrActions"
import { currentNodeReset } from "../../../redux/actions/currentNodeActions"
import { navigate } from "gatsby"

import IconPlan from "../icons/IconPlan"
import ModalCustom from "../Modal"
const FilterLine = ({ name, count }) => (
  <Flex
    alignItems="center"
    justifyContent="space-between"
    width="100%"
    px={3}
    py={4}
  >
    <Text textAlign="left">{name}</Text>
    <Text
      py={1}
      px={2}
      bg="primary"
      color="white"
      fontWeight="bold"
      sx={{ borderRadius: "20px" }}
    >
      {count}
    </Text>
  </Flex>
)

const FilterTitle = ({ title }) => (
  <>
    <Flex
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      py={2}
    >
      <Text fontSize="20rem" fontWeight="bold">
        {title}
      </Text>
      <IconPlan icon="chevron_down" size="24" color="primary" />
    </Flex>
    <Box height="1px" bg="#d8d8d8" />
  </>
)
const ModalFilters = ({
  activeFilters,
  items,
  lastCategorie,
  lastCategorieParent,
  lastCity,
  facetsCategories,
  facetsVilles,
  dispatch,
  word,
  isOpen,
  setIsOpen,
  location,
}) => {
  
  const filterCategorie = (categorie) => {
    setIsOpen(false)
    if (word === "") {
      navigate(categorie.url)
    } else {
      dispatch(fetchSolr(word, categorie.tid, lastCity, lastCategorieParent))
    }
  }

  const filterVille = (ville) => {
    setIsOpen(false)
    dispatch(fetchSolr(word, lastCategorie, ville.nid, lastCategorieParent))
  }
  const reinitFilters = () => {
    setIsOpen(false)
    dispatch(currentNodeReset())
    dispatch(solrResetFilters())
    if (word !== "") {
      dispatch(fetchSolr(word, false, false, lastCategorieParent))
    } else {
      if (location) {
        dispatch(solrResetItems())
        navigate(location.pathname)
      } else {
        navigate("/")
      }
    }
  }

  if (items.length === 0 || !items) {
    return <></>
  }
  const boxStyle = {
    py: 1,
    sx: {
      maxHeight: ["30vh", "30vh", "300px"],
      overflowY: "scroll",
    },
  }
  return (
    <ModalCustom
      title="Affiner votre recherche"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Box py={4} px={3}>
        <Flex pb={4} mx={-2} flexDirection={["column", "column", "row"]}>
          <Box width={[1, 1, 1 / 2]} px={2}>
            <FilterTitle title="Par communes" />
            <Box {...boxStyle}>
              {facetsVilles.map((ville, i) => (
                <Button
                  key={i}
                  width="100%"
                  display="block"
                  variant="ninja"
                  onClick={() => filterVille(ville)}
                >
                  <FilterLine {...ville} />
                </Button>
              ))}
            </Box>
          </Box>
          <Box width={[1, 1, 1 / 2]} px={2}>
            <FilterTitle title="Par catégories" />
            <Box {...boxStyle}>
              {facetsCategories.map((categorie, i) => (
                <Button
                  key={i}
                  width="100%"
                  variant="ninja"
                  onClick={() => filterCategorie(categorie)}
                  sx={
                    parseInt(categorie.tid) === parseInt(lastCategorie)
                      ? {
                          borderColor: "muted",
                          borderWidth: "1px",
                          borderStyle: "solid",
                          borderRadius: 4,
                        }
                      : {}
                  }
                >
                  <FilterLine {...categorie} />
                </Button>
              ))}
            </Box>
          </Box>
        </Flex>
        {activeFilters && (
          <Flex justifyContent={["center", "center", "flex-end"]}>
            <Button
              variant="simple"
              onClick={() => reinitFilters()}
              sx={{ "&:hover div": { color: "white" } }}
            >
              <Flex alignItems="center">
                <Text pr={2}>Annuler le filtre</Text>
                <IconPlan icon="close" size="24" color="muted" />
              </Flex>
            </Button>
          </Flex>
        )}
      </Box>
    </ModalCustom>
  )
}
const mapStateToProps = (state) => ({
  ...state.solr,
  location: state.global.location,
})
export default connect(mapStateToProps)(ModalFilters)
