import {
  USER_LOGIN,
  USER_LOGOUT,
  USER_UPDATE,
  USER_TOKEN,
  USER_SESSION,
  USER_RDV_LIST,
  USER_GET,
  USER_RESET,
  USER_FAVORIS_LIST,
  USER_PASS_RESET,
} from "../actions/currentUserActions"

const initialState = {
  user: false,
  token: null,
  xcsrftoken: null,
  rdvs: [],
  favoris: [],
  keeploggedin: false,
  loadedAt: 0,
  loading: false,
}

export { initialState }
export default function resultReducer(state = initialState, action) {
  if (process.env.NODE_ENV === "development") {
    console.log(action)
  }
  switch (action.type) {
    case USER_TOKEN:
      return {
        ...state,
        loading: true,
      }
    case USER_SESSION:
      return {
        ...state,
        loading: true,
      }
    case USER_SESSION + "_FAIL":
      return {
        ...state,
        loading: false,
      }


    case USER_GET + "_SUCCESS":
      return {
        ...state,
        user: action.payload.data,
        loading: false,
      }
    case USER_RESET + "_SUCCESS":
      return {
        ...state,
        // xcsrftoken: action.payload.data.token,
      }
    case USER_PASS_RESET + "_SUCCESS":
      return {
        ...state,
        ...action.payload.data,
        keeploggedin: action.payload.config.keeploggedin,
      }
    case USER_LOGIN + "_SUCCESS":
      return {
        ...state,
        ...action.payload.data,
        loadedAt: Math.floor(Date.now() / 1000),
        keeploggedin: action.payload.config.keeploggedin,
        loading: false,
      }
    // case USER_LOGIN + "_FAIL":
    //   return {
    //     ...state,
    //     loading: false,
    //   }
    case USER_RDV_LIST + "_SUCCESS":
      return {
        ...state,
        rdvs: action.payload.data,
      }

    case USER_FAVORIS_LIST + "_SUCCESS":
      return {
        ...state,
        favoris: action.payload.data,
      }
    case USER_UPDATE + "_SUCCESS":
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.data,
        },
      }

    case USER_TOKEN + "_SUCCESS":
      return {
        ...state,
        // token: action.payload.data,
        xcsrftoken: action.payload.data,
        loading: false,
      }
    case USER_LOGOUT + "_SUCCESS":
      return initialState
    default:
      // ALWAYS have a default case in a reducer
      return state
  }
}
