import React from "react"
import { Box, Flex, Text } from "rebass/styled-components"
import { connect } from "react-redux"
import { setFocusedNode } from "../../redux/actions/currentNodeActions"
import {
  getNodeTitle,
  formatDistance,
  getDistance,
  getValue,
} from "../../utils"
import { navigate } from "gatsby"
import IconPlan from "../../components/UI/icons/IconPlan"

const Lieu = ({
  focusedItem,
  node,
  isMap,
  dispatch,
  location,
  searchPos,
  searchAround,
}) => {

  const focusOn = () => {
    if (!isMap) {
      navigate(location.pathname, { state: { isMap: true } })
    }
    dispatch(setFocusedNode(node))
  }
  const title = getNodeTitle(node)
  var actionVisible = true
  if (isMap) {
    actionVisible = false
    if (focusedItem) {
      actionVisible =
        focusedItem.drupal_internal__nid === node.drupal_internal__nid
    }
  }
  return (
    <Box pr={isMap ? 2 : 0} onClick={focusOn} sx={{ cursor: "pointer" }}>
      <Box
        p={2}
        sx={{
          position: "relative",
          cursor: "pointer",
          transition: "all 300ms ease",
          "&:hover": {
            pl: 2,
            mr: actionVisible ? 0 : -2,
            "&:before": {
              // width: isMap ? (actionVisible ? '10px' : '5px') : '5px',
              width: isMap ? (actionVisible ? "10px" : "5px") : "0",
              opacity: 1,
            },
          },
          "&:before": {
            content: '""',
            position: "absolute",
            top: 0,
            opacity: actionVisible && isMap ? 1 : 0,
            bottom: 0,
            bg: "lieux",
            left: 0,
            width: actionVisible && isMap ? "10px !important" : "0",
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            transition: "all 300ms ease",
          },
        }}
      >
        <Flex
          pl={isMap ? 2 : 0}
          pr={2}
          justifyContent="stretch"
          alignItems="center"
        >
          <IconPlan
            icon={node.type === "street" ? "Street-Fill" : node.field_icon}
            size="24"
            color="lieux"
          />
          <Text pl={5}>{title}</Text>
        </Flex>
        {searchAround !== false && searchPos !== false && (
          <Text textAlign="right" color="primary" fontWeight="bold">
            {formatDistance(
              getDistance(
                { lat: searchPos[1], lon: searchPos[0] },
                {
                  lat: parseFloat(getValue(node, "field_geofield", "lat")),
                  lon: parseFloat(getValue(node, "field_geofield", "lon")),
                }
              )
            )}
          </Text>
        )}
      </Box>
      <Box ml={actionVisible && isMap ? 2 : 0} height="1px" bg="#d8d8d8" />
    </Box>
  )
}

const mapStateToProps = state => ({
  focusedItem: state.currentNode.focusedItem,
  location: state.global.location,
  searchAround: state.global.searchAround,
  searchPos: state.solr.searchPos,
})

export default connect(mapStateToProps)(Lieu)
