import { Input } from "@rebass/forms"
import React, { useState, useEffect, useRef } from "react"
import {
  Flex,
  Text,
  Button,
  Box,
  Heading,
  Image,
} from "rebass/styled-components"
import { getNodePath } from "../../../utils"
import IconPlan from "../icons/IconPlan"
import ModalCustom from "../Modal"

const ShareButton = ({
  isMobile,
  node,
  isMap,
  width,
  displayLabel,
  buttonSx,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isCopied, setIsCopied] = useState(false)
  const inputRef = useRef(null)
  const nodePath = getNodePath(node, true)
  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setIsCopied(false), 6000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCopied])
  const url = "https://plan.nc" + nodePath
  const share = async () => {
    if (isMobile) {
      if (typeof navigator.share === "function") {
        try {
          await navigator.share({
            title: node.title,
            text: node.title,
            url: url,
          })
        } catch (err) {
          console.log(err)
        }
      } else {
        setIsOpen(true)
      }
    } else {
      setIsOpen(true)
    }
  }
  buttonSx = buttonSx || {}
  width = width || (isMap ? [1 / 6] : [1 / 6, "auto", "auto", 1])
  displayLabel =
    displayLabel ||
    (isMap ? ["none", "block", "none"] : ["none", "none", "block"])
  const links = [
    {
      url: "https://facebook.com/sharer.php?u=" + url + "&amp;t=" + node.title,
      name: "facebook",
      title: "Partager sur Facebook",
    },
    {
      url:
        "https://www.facebook.com/dialog/send?app_id=865530501355076&link=" +
        url +
        "&redirect_uri=" +
        url,
      name: "messenger",
      title: "Partager sur Messenger",
    },
    {
      url:
        "https://www.linkedin.com/shareArticle?mini=true&url=" +
        url +
        "&title=" +
        node.title,
      name: "linkedin",
      title: "Partager sur Linkedin",
    },
  ]

  return (
    <>
      <ModalCustom
        isOpen={isOpen !== false}
        setIsOpen={setIsOpen}
        containerSx={{
          minWidth: ["100%", "auto"],
          maxWidth: ["100%", "540px"],
        }}
      >
        <Box p={4} pt={0}>
          <Heading as="h3" mb={4} textAlign="center">
            Partager
          </Heading>

          <Flex py={4} mb={3} justifyContent="center" bg="grayLight">
            {links.map((link) => (
              <Button
                variant="ninja"
                key={"share-" + node.drupal_internal__nid + "-" + link.name}
                mx={2}
                width="40px"
                sx={{
                  img: { transform: "scale(1)", transition: "all 300ms ease" },
                  "&:hover img": { transform: "scale(1.2)" },
                }}
              >
                <a
                  aria-label="Partager sur "
                  target="_blank"
                  rel="noreferrer"
                  href={link.url}
                  title={link.title}
                >
                  <Image src={"/social/" + link.name + ".svg"} />
                </a>
              </Button>
            ))}
          </Flex>
          <Text sx={{ overflow: "hidden" }} textAlign="center">
            {url}
          </Text>
          <Flex py={4} justifyContent="center">
            <Input ref={inputRef} value={url} display="none" readOnly />
            <Button
              variant={isCopied ? "success" : "primary"}
              mx={1}
              onClick={() => {
                navigator.clipboard.writeText(url).then(
                  () => {
                    setIsCopied(true)
                  },
                  () => {
                    inputRef.current.select()
                    document.execCommand("copy")
                    setIsCopied(true)
                  }
                )
              }}
            >
              {isCopied ? "Url Copiée!" : "Copier l'url"}
            </Button>
          </Flex>
        </Box>
      </ModalCustom>
      <Flex px={1} width={width}>
        <Button
          variant="topAction"
          onClick={share}
          as="div"
          p={2}
          px={isMap ? [2, 3] : [2]}
          mt={isMap ? 0 : [0, 0, 0, 2]}
          display="flex"
          width={1}
          justifyContent={isMap ? "center" : ["center", "center", "flex-start"]}
          sx={{ ...buttonSx }}
        >
          <Flex alignItems="center" opacity={1} sx={{ transition: "base" }}>
            <IconPlan icon={"share"} color={"primary"} size="24" />
            <Text pl={2} display={displayLabel}>
              Partager
            </Text>
          </Flex>
        </Button>
      </Flex>
    </>
  )
}

export default ShareButton
