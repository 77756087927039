import React, { useEffect, useState } from "react"
import Modal from "styled-react-modal"
// import { setLastAdDate } from "../../../redux/actions/persistAction"
import {
  setNewSession,
  setReadyToUse,
} from "../../../redux/actions/globalAction"
import { connect } from "react-redux"
import { AdSlot } from "react-dfp"
import { Box, Flex } from "rebass/styled-components"
import { DFPSlotsProvider } from "react-dfp"
// import moment from 'moment'
import { withTheme } from "styled-components"

import IconPlan from "../icons/IconPlan"

const StyledModal = Modal.styled`
  display: ${(props) => (props.opacity > 0 ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity ease 500ms;
`

const Splash = ({ lastAdDate, dispatch, isMap, newSession, ...props }) => {
  const [opacity, setOpacity] = useState(0)
  const [open, setOpen] = useState(false)
  const [isRendered, setIsRendered] = useState(false)

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     if (parseInt(props.theme.breakpoints[1]) > window.innerWidth) {
  //       return;
  //     }
  //     const nowMoment = moment();
  //     const lastAdMoment = moment(lastAdDate, "X");
  //     if ((nowMoment.unix() - lastAdMoment.unix()) > 86400 || nowMoment.format('E') !== lastAdMoment.format('E')) {
  //       dispatch(setLastAdDate(nowMoment.unix()));
  //       // setOpen(true);
  //     }
  //     if (!location) {
  //       setOpen(true);
  //     }
  //   }
  // }, [lastAdDate, dispatch]);

  useEffect(() => {
    if (!newSession) {
      dispatch(setReadyToUse(true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (parseInt(props.theme.breakpoints[1]) > window.innerWidth) {
        dispatch(setReadyToUse(true))
        return
      }
      if (newSession && isRendered && open === false) {
        dispatch(setNewSession(false))
        setOpen(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSession, dispatch, isRendered])
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false)
      }, 20000)
    }
  }, [open])
  const close = () => {
    setOpen(false)
    dispatch(setReadyToUse(true))
  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1)
    }, 10)
  }

  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0)
      setTimeout(resolve, 200)
    })
  }
  if (!isMap) {
    return <></>
  }
  return (
    <StyledModal
      isOpen={open}
      beforeClose={beforeClose}
      opacity={opacity}
      backgroundProps={{ opacity }}
      onBackgroundClick={close}
      onEscapeKeydown={close}
      {...props}
    >
      <DFPSlotsProvider dfpNetworkId="21617937854" lazyLoad={true}>
        <Box sx={{ transform: ["scale(.7)", "none"] }}>
          <Flex justifyContent="flex-end">
            <IconPlan
              p={2}
              pb={2}
              icon="close"
              size="24"
              onClick={close}
              bg="primary"
              color="white"
              sx={{
                cursor: "pointer",
                borderTopRightRadius: 4,
                borderTopLeftRadius: 4,
              }}
            />
          </Flex>
          <Box
            p={2}
            sx={{ borderRadius: "6px", borderTopRightRadius: 0 }}
            bg="white"
          >
            <AdSlot
              sizes={[[400, 400]]}
              adUnit="intersticiel-400x400"
              onSlotRender={() => {
                setIsRendered(true)
              }}
              onSlotIsViewable={() => {
                afterOpen()
              }}
            />
          </Box>
        </Box>
      </DFPSlotsProvider>
    </StyledModal>
  )
}

const mapStateToProps = (state) => ({
  lastAdDate: state.persist.lastAdDate,
  isMap: state.global.isMap,
  newSession: state.global.newSession,
})
export default connect(mapStateToProps)(withTheme(Splash))
