import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { connect } from "react-redux"
import { setCurrentNode } from "../redux/actions/currentNodeActions"
import { setIsMap } from "../redux/actions/globalAction"
import {
  getValue,
  createCategorieBreadCrumb,
  renameImage,
  hasRdv,
} from "../utils"
import Page from "./Page"
import Poi from "./poi"
import { getFavoris } from "../redux/actions/currentUserActions"
// import { wrapItem } from "../../wrap-with-provider"

const NodeTemplate = ({
  data,
  isMap,
  location,
  pageContext,
user,
dispatch,
  ...props
}) => {
  const { nodeCompany: node } = data
  var crumbs = []
  const titleParts = [node.title]
  const categorieName = getValue(node, "field_co_cats", "0.name", "")
  titleParts.push(categorieName)
  if (categorieName) {
    crumbs = createCategorieBreadCrumb(getValue(node, "field_co_cats", "0"))
  }
  const villeName = getValue(node, "field_city", "name", "")
  titleParts.push(villeName)
  const code_offre = getValue(
    node,
    "field_offer",
    "drupal_internal__tid",
    false
  )
  const descriptions = {
    desc_gratuit: `Retrouvez facilement le numéro de téléphone et l'adresse de ${node.title}, à ${villeName} sur l'annuaire Plan.nc.`,
    desc_gold: `Retrouvez les horaires et tous les services de ${node.title}, à ${villeName}. Trouvez les numéros de téléphone et adresses des entreprises sur Plan.nc.`,
    desc_premium: hasRdv(node)
      ? `Prenez RDV chez ${node.title}, à ${villeName}. ${categorieName}`
      : `Retrouvez les horaires et tous les services de ${node.title}, à ${villeName}. ${categorieName}`,
  }
  crumbs.push({
    path: node.path.alias,
    title: node.title,
  })
  useEffect(() => {
    dispatch(setCurrentNode(node))
    if (user) {
      dispatch(getFavoris())
    }
    if (isMap !== pageContext.isMap) {
      dispatch(setIsMap(pageContext.isMap))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // const params = new URLSearchParams(location.search)
  // const showNav = params.get("nav") === "yes"

  // const showNav = false
  return (
    <Page
      crumbs={crumbs}
      title={titleParts.join(" | ")}
      location={location}
      description={descriptions["desc" + code_offre]}
      keywords={getValue(node, "field_co_keywords", false, "")}
      image={renameImage(
        getValue(
          node,
          "field_co_logo",
          "relationships.field_media_image.publicUrl",
          "/og-image.png"
        )
      )}
      forceMap={pageContext.isMap}
    >
      <Poi node={node} isMap={pageContext.isMap} />
    </Page>
  )
}


const mapStateToProps = (state) => ({
  user: state.currentUser.user,
  node: state.currentNode.item,
  isMap: state.global.isMap,
})
// export default NodeTemplate
export default connect(mapStateToProps)(NodeTemplate)



export const nodeQuery = graphql`
  fragment CompanyNode on node__company {
    body {
      value
      processed
    }
    field_geofield {
      lat
      lon
    }
    field_co_keywords
    field_co_type
    field_date
    field_email
    field_fax
    field_mailbox
    field_facebook {
      uri
    }
    field_instagram {
      uri
    }
    field_linkedin {
      uri
    }
    field_co_infos {
      processed
    }
    changed
    created
    drupal_id
    drupal_internal__nid
    field_address_nb
    field_brands_text
    field_mailbox_text
    field_opentime {
      all_day
      comment
      day
      endhours
      starthours
    }
    field_opentime_more
    field_payment
    field_phone
    field_phone_mob
    field_poi_enable
    field_quote_request
    field_rdv_email
    field_rdv_email_notif
    field_rdv_enable
    field_rdv_infos {
      processed
    }
    field_rdv_leap_time
    field_rdv_schedules {
      starthours
      endhours
      day
      comment
      all_day
    }
    field_slogan
    field_street {
      drupal_internal__target_id
    }
    field_street_text
    field_website {
      uri
    }
    field_youtube {
      uri
    }
    field_zip_code
    id
    path {
      alias
      langcode
      pid
    }
    promote
    status
    sticky
    title
    node_type {
      drupal_internal__target_id
    }
    relationships {
      field_city {
        name
      }
      field_co_files {
        relationships {
          field_media_document {
            publicUrl
            filename
          }
        }
      }
      field_co_cover {
        relationships {
          field_media_image {
            publicUrl
          }
        }
      }
      field_co_gallery {
        field_media_image {
          alt
          title
        }
        relationships {
          field_media_image {
            publicUrl
          }
        }
      }
      field_co_cats {
        drupal_internal__tid
        path {
          alias
        }
        name
        relationships {
          ...CategoryParents
        }
      }
      field_co_logo {
        relationships {
          field_media_image {
            publicUrl
          }
        }
      }
      field_co_videos {
        field_media_oembed_video
      }
      field_district {
        title
        drupal_internal__nid
      }
      field_offer {
        drupal_internal__tid
        name
        weight
      }
      field_street {
        drupal_internal__nid
        title
        field_street_prefix
      }
      field_co_contacts {
        field_email
        field_job_role
        field_phone
        field_phone_mob
        field_title
      }
      field_co_slider_free {
        relationships {
          field_media_image {
            publicUrl
          }
        }
      }
    }
  }
  query OfflineNodeTemplate($nid: Int!) {
    nodeCompany(drupal_internal__nid: { eq: $nid }) {
      ...CompanyNode
    }
  }
`
