import { sendEvent } from "../../utils"
export const CURRENT_NODE_RESET = "CURRENT_NODE_RESET"
export const SET_CURRENT_NODE = "SET_CURRENT_NODE"
export const SET_FOCUSED_NODE = "SET_FOCUSED_NODE"
export const SET_CURRENT_NODE_ROUTING = "SET_CURRENT_NODE_ROUTING"
export const setCurrentNode = node => {
if (node?.drupal_internal__nid) {
  sendEvent("visite", "view-visite", node.drupal_internal__nid, 1)
}
  return {
    type: SET_CURRENT_NODE,
    payload: {
      data: node,
    },
  }
}
export const setFocusedNode = (node, fromMap) => {
  if (node?.drupal_internal__nid) {
    sendEvent("geoloc", "clic-geoloc", node.drupal_internal__nid, 1)
  }

  fromMap = fromMap || false
  return {
    type: SET_FOCUSED_NODE,
    payload: {
      data: node,
      fromMap: fromMap,
    },
  }
}

export const currentNodeReset = () => ({
  type: CURRENT_NODE_RESET,
})

export const setCurrentRouting = data => ({
  type: SET_CURRENT_NODE_ROUTING,
  payload: {
    data: data,
  },
})
