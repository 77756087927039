
import { store } from "../store"

export const USER_LOGIN = "USER_LOGIN"
export const USER_SUBSCRIBE = "USER_SUBSCRIBE"
export const USER_SYNC = "USER_SYNC"
export const USER_UPDATE = "USER_UPDATE"
export const USER_RESET_PASSWORD = "USER_RESET_PASSWORD"
export const USER_LOGOUT = "USER_LOGOUT"
export const USER_TOKEN = "USER_TOKEN"
export const USER_RDV_LIST = "USER_RDV_LIST"
export const USER_FAVORIS_LIST = "USER_FAVORIS_LIST"
export const USER_RESET = "USER_RESET"
export const USER_GET = "USER_GET"
export const USER_CANCEL = "USER_CANCEL"
export const USER_SESSION = "USER_SESSION"
export const USER_PASS_RESET = "USER_PASS_RESET"
export const USER_CREATE_CODE = "USER_CREATE_CODE"
export const USER_CHECK_CODE = "USER_CHECK_CODE"

const setCookie = (cname, cvalue, exdays) => {
  if (document) {
    var d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    var expires = "expires=" + d.toUTCString()
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
  }
}

const removeCookie = (cname) => {
  if (document) {
    document.cookie = cname + "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
  }
}

const loginResponse = (data) => {
  data = typeof data === "string" ? JSON.parse(data) : data
  if (
    data.sessid &&
    data.session_name &&
    process.env.NODE_ENV === "development"
  ) {
    setCookie(data.session_name, data.sessid, 2)
  }
  data.user = parseInt(data.userId) === 0 ? false : { uid: data.userId }
  return data
}

const logoutResponse = (data) => {
  const currentUser = store.getState().currentUser
  if (currentUser.user) {
    removeCookie(currentUser.session_name)
  }
  if (process.env.NODE_ENV === "development") {
    console.log(data)
  }
  return data
}

export const currentSession = () => {
  return {
    type: USER_SESSION,
    payload: {
      request: {
        method: "get",
        url: "/session/token",
        transformResponse: [loginResponse],
      },
    },
  }
}

export const getUser = (uid) => {
  return {
    type: USER_GET,
    payload: {
      client: "DrupalRest",
      request: {
        method: "get",
        url: "user/" + uid,
      },
    },
  }
}

export const getToken = (withCredentials) => {
  withCredentials = withCredentials || false
  return {
    type: USER_TOKEN,
    payload: {
      request: {
        method: "get",
        // withCredentials:
        //   process.env.NODE_ENV !== "development" ? withCredentials : false,
        responseType: "text",
        url: "/session/token",
        // headers: {
        //   Authorization: "Basic ai5jYW5AcmlrLm5jOmouY2FuQHJpay5uYw==",
        // },
      },
    },
  }
}

export const user_pass_reset = (uid, timestamp, newpass, hashed_pass) => {
  return {
    type: USER_PASS_RESET,
    payload: {
      request: {
        client: "DrupalRest",
        method: "post",
        url: "user/user_pass_reset?_format=json",
        data: {
          uid: uid,
          timestamp: timestamp,
          newpass: newpass,
          hashed_pass: hashed_pass,
        },
        headers: {
          "Content-Type": "application/json",
          "X-AUTH-TOKEN": "",
        },
      },
    },
  }
}

export const user_reset = (uid, timestamp, hashed_pass,new_pass) => {
  return {
    type: USER_RESET,
    payload: {
      request: {
        method: "post",
        url: "user/user_pass_reset",
        data: {
          uid: uid,
          timestamp: timestamp,
          hashed_pass: hashed_pass,
          new_pass,
        },
        keeploggedin: true,
        transformResponse: [loginResponse],
      },
    },
  }
}

export const login = (user, keeploggedin) => {
  keeploggedin = keeploggedin || false
  return {
    type: USER_LOGIN,
    payload: {
      client: "DrupalRest",
      request: {
        method: "post",
        url: "api/v1/auth/token",
        data: user,
        keeploggedin: keeploggedin,
        transformResponse: [loginResponse],
      },
    },
  }
}

export const subscribe = (user, autologin) => {
  autologin = autologin || false
  // if (autologin) {
  //   return {
  //     types: [
  //       USER_SUBSCRIBE,
  //       USER_LOGIN + "_SUCCESS",
  //       USER_SUBSCRIBE + "_FAIL",
  //     ],
  //     payload: {
  //       request: {
  //         method: "post",
  //         url: "user/register?_format=json",
  //         data: user,
  //         keeploggedin: true,
  //         transformResponse: [subscribeResponse],
  //       },
  //     },
  //   }
  // }
  return {
    type: USER_SUBSCRIBE,
    payload: {
      request: {
        method: "post",
        url: "user/register?_format=json",
        data: user,
      },
    },
  }
}

export const getRdvList = () => {
  const currentUser = store.getState().currentUser
  if (currentUser.user) {
    return {
      type: USER_RDV_LIST,
      payload: {
        client: "DrupalRest",
        request: {
          url: "rendez_vous_user",
        },
      },
    }
  }
}

export const getFavoris = () => {
  const currentUser = store.getState().currentUser
  if (currentUser.user) {
    return {
      type: USER_FAVORIS_LIST,
      payload: {
        client: "DrupalRest",
        request: {
          url: "my-favs",
        },
      },
    }
  }
}

export const cancelUser = (uid) => {
  const currentUser = store.getState().currentUser
  return {
    type: USER_CANCEL,
    payload: {
      request: {
        method: "DELETE",
        url: "user/" + uid + "/cancel?_format=json",
        headers: {
          "X-AUTH-TOKEN": currentUser.token,
        },
      },
    },
  }
}

export const update = (user, uid) => {
  return {
    type: USER_UPDATE,
    payload: {
      client: "DrupalRest",
      request: {
        method: "PATCH",
        url: "user/" + uid,
        data: user,
      },
    },
  }
}

export const reset_password = (user) => {
  return {
    type: USER_RESET_PASSWORD,
    payload: {
      request: {
        method: "post",
        url: "user/password?_format=json",
        data: user,
        transformResponse: [
          function (data) {
            if (process.env.NODE_ENV === "development") {
              console.log(data)
            }
            return data
          },
        ],
      },
    },
  }
}

export const removeCurrentUser = () => {
  return {
    type: USER_LOGOUT + "_SUCCESS",
  }
}

export const logout = () => {
  return {
    type: USER_LOGOUT,
    payload: {
      client: "DrupalRest",
      request: {
        method: "post",
        url: "api/v1/auth/logout",
        transformResponse: [logoutResponse],
      },
    },
  }
}

export const user_create_code = (uid) => {
  return {
    type: USER_CREATE_CODE,
    payload: {
      client: "DrupalRest",
      request: {
        method: "post",
        url: "phone-validation/create",
        data: {
          uid: uid,
        },
      },
    },
  }
}

export const user_check_code = (uid, code) => {
  return {
    type: USER_CHECK_CODE,
    payload: {
      client: "DrupalRest",
      request: {
        method: "post",
        url: "user/validation",
        data: {
          uid: uid,
          code: code,
        },
      },
    },
  }
}
