import React from "react"
import { Box, Image, Text, Flex } from "rebass/styled-components"
import { getValue, renameImage } from "../../../utils"
import LazyLoad from "react-lazyload"
import Block from "./Block"
import ContactLink from "./ContactLink"
import SquareBox from "../SquareBox"

const Equipe = ({ node, isMap }) => {

const postes = getValue(node, "field_co_contacts", false, []).map(
  (contact) => ({
    fonction: contact?.field_job_role || false,
    nom: contact?.title || false,
    photo: false,
    gsm: getValue(contact, "field_phone_mob", "Phone", false),
    phone: getValue(contact, "field_phone", "Phone", false),
    mail: contact?.field_email || false,
  })
)

  if (
    postes.filter(
      poste =>
        poste.nom || poste.fonction || poste.gsm || poste.phone || poste.mail
    ).length === 0
  ) {
    return <></>
  }
  return (
    <Block title={"Équipe de " + node.title}>
      <Flex flexWrap="wrap">
        {postes
          .filter(
            (poste) =>
              poste.nom ||
              poste.fonction ||
              poste.gsm ||
              poste.phone ||
              poste.mail
          )
          .map((poste, index) => (
            <Box
              width={isMap ? 1 : [1, 1, 1 / 3]}
              key={"poste-" + index}
              pb={isMap ? 4 : [4, 0]}
            >
              <Flex flexDirection={["row", "row", "column"]}>
                {poste.photo && (
                  <Box width={[1 / 3]} pb={2}>
                    <SquareBox>
                      <LazyLoad overflow={isMap}>
                        <Image
                          src={
                            renameImage(poste.photo, false, "square_400") +
                            "?vid=" +
                            node.vid
                          }
                          alt={poste.nom ? poste.nom : "Profil"}
                        />
                      </LazyLoad>
                    </SquareBox>
                  </Box>
                )}

                <Box
                  width={!poste.photo ? [1] : [2 / 3, 2 / 3, 1]}
                  pl={!poste.photo ? [0] : [2, 2, 0]}
                  pr={!poste.photo ? [0] : [0, 0, 2]}
                >
                  {poste.nom && (
                    <Text fontWeight="bold" mb={2}>
                      {poste.nom}
                    </Text>
                  )}
                  {poste.fonction && <Text mb={2}>{poste.fonction}</Text>}
                  {poste.phone && (
                    <ContactLink mb={2} type="fixe" {...poste.phone} />
                  )}
                  {poste.gsm && (
                    <ContactLink mb={2} type="mobile" {...poste.gsm} />
                  )}
                  {poste.mail && (
                    <ContactLink
                      mb={2}
                      type="mail"
                      link={poste.mail}
                      text={poste.mail}
                    />
                  )}
                </Box>
              </Flex>
            </Box>
          ))}
      </Flex>
    </Block>
  )
}
export default Equipe
