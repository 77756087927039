/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import { WrapLayout } from "./wrap-with-provider"
export const wrapRootElement = WrapLayout

// export const onServiceWorkerUpdateReady = () => {
//   window.location.reload();
// }