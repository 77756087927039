import colors from "./colors"
const variants = {
  avatar: {
    width: "avatar",
    height: "avatar",
    borderRadius: "circle",
  },
  categorieCircle: {
    display: "inline-block",
    margin: "0 auto",
    bg: "primary",
    width: "48px",
    height: "48px",
    borderRadius: 9999,
    color: "#fff",
  },
  badges: {
    display: "block",
    px: 2,
    py: 1,
    borderRadius: 9999,
    fontSize: 0,
    minWidth: "unset",
  },
  badge: {
    primary: {
      variant: "variants.badges",
      color: "white",
      bg: "primary",
    },
    primaryGradiant: {
      variant: "variants.badges",
      color: "white",
      bg: "primary",
      background: colors.gradiantPrimary,
    },
    gradiantText: {
      variant: "variants.badges",
      color: "white",
      bg: "text",
      background: colors.gradiantText,
    },
    gradiantPurple: {
      variant: "variants.badges",
      color: "white",
      bg: "purple",
      background: colors.gradiantPurple,
    },
    offrepremium: {
      variant: "variants.badges",
      color: "white",
      bg: "purple",
      background: colors.gradiantPurple,
    },
    offregratuit: {
      variant: "variants.badges",
      color: "white",
      bg: "primary",
      background: colors.gradiantPrimary,
      "&>*": {
        opacity: 0,
      },
    },
    offregold: {
      variant: "variants.badges",
      color: "white",
      bg: "golden",
      background: colors.gradiantGold,
    },
  },
  card: {
    p: 2,
    bg: "background",
    boxShadow: "card",
  },
  link: {
    color: "inherit",
    textDecoration: "none",
    fontWeight: "inherit",
  },
  linktext: {
    color: "inherit",
    textDecoration: "inherit",
  },
  linktextUnderline: {
    variant: "variants.linktext",
    color: "inherit",
    textDecoration: "underline",
    position: "relative",

    // "&:before": {
    //   content: '""',
    //   position: "absolute",
    //   bottom: '-3px',
    //   left: 0,
    //   height: "1px",
    //   width: 0,
    //   transition: "all 300ms ease",
    //   bg: "primary"
    // },
    // "&:hover": {
    //   "&:before": {
    //     width: "100%",
    //   }
    // }
  },
  darkBg: {
    backgroundColor: "primary",
    color: "background",
  },
  bgImg: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  squareBox: {
    position: "relative",
    "&:after": {
      content: "''",
      display: "block",
      paddingBottom: "100%",
    },
  },
  nav: {
    fontSize: 1,
    fontWeight: "bold",
    display: "inline-block",
    p: 2,
    color: "inherit",
    textDecoration: "none",
    "&:hover,&:focus,.active": {
      color: "primary",
    },
  },
  searchBar: {
    // backgroundColor: "primary",
    // background: colors.gradiantPrimary,
  },
  triangle: {
    height: "20px",
    width: "20px",
    overflow: "hidden",
    "&:after": {
      content: "''",
      display: "block",
      width: "150%",
      height: "75%",
      transform: "translate(0%,-25%) rotate(45deg)",
      backgroundColor: colors.primary,
      background: colors.gradiantPurple,
    },
  },
  trianglepremium: {
    variant: "variants.triangle",
    "&:after": {
      content: "''",
      display: "block",
      width: "150%",
      height: "75%",
      transform: "translate(0%,-25%) rotate(45deg)",
      backgroundColor: colors.purple,
      background: colors.gradiantPurple,
    },
  },
  trianglegold: {
    variant: "variants.triangle",
    "&:after": {
      content: "''",
      display: "block",
      width: "150%",
      height: "75%",
      transform: "translate(0%,-25%) rotate(45deg)",
      backgroundColor: colors.golden,
      background: colors.gradiantGold,
    },
  },
  navbar: {
    width: ["100%"],
    transition: "all 300ms ease",
    backgroundColor: colors.primary,
    background: colors.gradiantPrimary,
    color: "#FFF",
    a: {
      color: "#FFF",
    },
    ".logo": {
      display: "block",
      lineHeight: 0,
    },
    position: "fixed",
    zIndex: 10,
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.4)",
  },
  container: {
    width: "100%",
    maxWidth: ["100%", "100%", "100%", "960px"],
    minWidth: ["0", "0", "0", "960px"],
    m: "0 auto",
    flexbox: true,
    px: [2, 2, 4],
  },
  containerFull: {
    variant: "variants.container",
    maxWidth: ["100%", "100%", "100%", "100%"],
  },
  headerButton: {
    borderRadius: 4,
    cursor: "pointer",
    alignItems: "center",
    bg: "primaryDark",
    color: "white",
    fontWeight: "bold",
    px: 3,
  },
  headerButtonActive: {
    variant: "variants.headerButton",
    boxShadow: "headerButton",
    bg: "white",
    color: "text",
  },
  grid: {
    flexWrap: "wrap",
  },
  section: {
    width: "100%",
    py: [30, 30, 60],
  },
}

export default variants
