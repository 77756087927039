import React, { useState } from "react"
import { Input, Select } from "@rebass/forms/styled-components"

import { Box, Text } from "rebass/styled-components"

const AutocompleteSelect = ({ formElement, onChange, maxlines }) => {
  maxlines = maxlines || 10
  const [search, setSearch] = useState("")
  const [selected, setSelected] = useState(false)
  return (
    <>
      <Input
        value={search}
        placeholder={formElement.placeholder}
        onChange={(e) => {
          setSelected(false)
          setSearch(e.target.value)
        }}
      ></Input>
      {search !== "" && !selected && (
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              borderColor: "gray",
              borderWidth: "1px",
              borderStyle: "solid",
              borderRadius: "default",
            }}
            backgroundColor={"white"}
            width={1}
          >
            {formElement.options
              .filter((optionObj) =>
                optionObj.label.toLowerCase().includes(search.toLowerCase())
              )
              .slice(0, maxlines)
              .map((optionObj, iOption) => (
                <Box
                  key={iOption}
                  value={optionObj.value}
                  p={2}
                  onClick={() => {
                    setSearch(optionObj.label)
                    setSelected(true)
                    onChange({
                      target: { selectedOptions: true, value: optionObj.value },
                    })
                  }}
                  sx={{
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "primary", color: "white" },
                  }}
                >
                  <Text>{optionObj.label}</Text>
                </Box>
              ))}
          </Box>
        </Box>
      )}
      <Box display="none">
        <Select
          id={formElement.name}
          name={formElement.name}
          defaultValue={formElement.default}
          onChange={(e) => {
            onChange(e)
          }}
        >
          {formElement.options
            .map((optionObj, iOption) => (
              <option key={iOption} value={optionObj.value}>
                {optionObj.label}
              </option>
            ))}
        </Select>
      </Box>
    </>
  )
}

export default AutocompleteSelect
