import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { connect } from "react-redux"
import {
  fetchSolr,
  solrResetItems,
  setLastListPath,
} from "../redux/actions/SolrActions"
import { createCategorieBreadCrumb } from "../utils"
import Page from "./Page"
import Results from "./results/Results"
import SubCats from "../components/UI/Categories/SubCats"

const Categorie = ({
  data,
  location,
  items,
  loading,
  dispatch,
  currentLocation,
  path,
}) => {
  const { taxonomyTermCat: categorie, taxonomyTermCity: ville } = data
const isParent = categorie?.relationships?.parent.length===0
categorie.relationships.node__company =
  categorie.relationships.node__company || []
// const results = categorie.relationships.node__company.sort((a, b) => {
//   if (
//     a.relationships.field_offer.weight === b.relationships.field_offer.weight
//   ) {
//     return a.title.localeCompare(b.title)
//   }
//   return a.relationships.field_offer.weight - b.relationships.field_offer.weight
// })
  const crumbs = createCategorieBreadCrumb(categorie)
  crumbs.push({
    path: path,
    title: ville.name,
  })
  useEffect(() => {
    if (currentLocation.pathname !== location.pathname) {
      dispatch(solrResetItems())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined" && !loading && !items) {
      if (!isParent)  {
          dispatch(
            fetchSolr(
              false,
              categorie.drupal_internal__tid,
              ville.drupal_internal__tid,
              false
            )
          )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loading,
    items,
    categorie.drupal_internal__tid,
    categorie.relationships,
    dispatch,
  ])

  useEffect(() => {
    if (!loading && items) {
      dispatch(setLastListPath(location.pathname))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, items])

  const description =
    "Retrouvez toutes les entreprises " +
    categorie.name +
    " situées à " +
    ville.name +
    " sur Plan.nc. Trouvez les numéros de téléphone et adresses des entreprises de votre quartier ou de votre ville sur Plan.nc"
  return (
    <Page
      crumbs={crumbs}
      title={categorie.name + " | " + ville.name}
      location={location}
      description={description}
      filters={!isParent}
      showCrumbs={true}
    >
      {isParent ? (
        <SubCats categories={data.allTaxonomyTermCat.nodes} />
      ) : (
        <>{items && <Results loading={false} results={items} />}</>
      )}
    </Page>
  )
}
const mapStateToProps = (state) => ({
  items: state.solr.items,
  loading: state.solr.loading,
  currentLocation: state.global.location,
})

export default connect(mapStateToProps)(Categorie)

export const categorieQuery = graphql`
  query CategorieVilleTemplate($category_id: String!, $city_id: String!) {
    taxonomyTermCat(drupal_id: { eq: $category_id }) {
      drupal_internal__tid
      name
      description {
        processed
        value
      }
      path {
        alias
      }
      relationships {
        node__company {
          ...CompanyNode
        }
        parent {
          name
          path {
            alias
          }
          relationships {
            parent {
              name
              path {
                alias
              }
              relationships {
                parent {
                  name
                  path {
                    alias
                  }
                  relationships {
                    parent {
                      name
                      path {
                        alias
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    taxonomyTermCity(drupal_id: { eq: $city_id }) {
      drupal_internal__tid
      name
      path {
        alias
      }
    }
    allTaxonomyTermCat(
      filter: {
        relationships: {
          parent: { elemMatch: { drupal_id: { eq: $category_id } } }
          node__company: {
            elemMatch: {
              id: { ne: null }
              relationships: { field_city: { drupal_id: { eq: $city_id } } }
            }
          }
        }
      }
      sort: { name: ASC }
    ) {
      nodes {
        drupal_internal__tid
        drupal_id
        name
        path {
          alias
        }
        relationships {
          parent {
            drupal_id
          }
          node__company {
            drupal_id
          }
        }
      }
    }
  }
`
