import React, { Fragment, useEffect, useState } from "react"
import { Box, Text, Button } from "rebass/styled-components"
import { connect } from "react-redux"
import { motion } from "framer-motion"
import { Link } from "gatsby"
import { DFPSlotsProvider } from "react-dfp"
import Teaser from "../../components/UI/Teaser"
import Poi from "./poi"
import Pub from "./Pub"
import Loader from "../../components/UI/Loader.js"
import { sendEvent } from "../../utils"
import { withIsVisible } from "react-is-visible"
const variantsList = {
  openResults: {
    transition: { staggerChildren: 0.1, delayChildren: 0 },
  },
  closedResults: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
}

const variants = {
  openResults: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closedResults: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const Results = ({
  loading,
  results,
  message,
  isMap,
  spellcheckSuggestions,
  searchPos,
  searchAround,
}) => {
  //  return <pre>{JSON.stringify(results, null, 2)}</pre>
  if (!results[0]?.drupal_internal__nid) {
    // return <pre>{JSON.stringify(results, null, 2)}</pre>
  }
  const [activeAnim, setActiveAnim] = useState(false)
  useEffect(() => {
    setActiveAnim(true)
  }, [results])
  const Animwrapper = ({ children, active, ...props }) => {
    if (active) {
      return (
        <motion.div
          variants={variantsList}
          onAnimationComplete={() => setActiveAnim(false)}
          initial={activeAnim ? "closedResults" : false}
          animate="openResults"
          {...props}
        >
          {children}
        </motion.div>
      )
    }
    return <Box {...props}>{children}</Box>
  }

  const AnimItemwrapperNoVisible = ({
    children,
    active,
    isVisible,
    node,
    ...props
  }) => {
    const [viewed, setViewed] = useState(false)
    useEffect(() => {
      let timer1

      if (isVisible && node && !viewed) {
        timer1 = setTimeout(function () {
          setViewed(true)
          clearTimeout(timer1)
          sendEvent("affichage", "view-affichage", node.drupal_internal__nid, 1)
        }, 500)
      }
      if (!isVisible) {
        clearTimeout(timer1)
      }
      return () => {
        clearTimeout(timer1)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible])
    if (active) {
      return (
        <motion.div variants={variants} {...props}>
          {children}
        </motion.div>
      )
    }
    return <Fragment {...props}>{children}</Fragment>
  }

  const AnimPubWrapper = ({ children, active, node, ...props }) => {
    if (active) {
      return (
        <motion.div variants={variants} {...props}>
          {children}
        </motion.div>
      )
    }
    return <Fragment {...props}>{children}</Fragment>
  }
  const AnimItemwrapper = withIsVisible(AnimItemwrapperNoVisible)

  var indexEntreprise = false
  message = message || "Pas de résultat trouvé"
  const voies =
    !loading && results
      ? results.filter((resultItem) => resultItem.type === "street")
      : []
  const lieux =
    !loading && results
      ? results.filter((resultItem) => resultItem.type === "place")
      : []
  var entreprises =
    !loading && Array.isArray(results)
      ? results.filter((resultItem) => resultItem.type === "company")
      : []
  // if (!loading && searchAround && searchPos && entreprises.length === 0) {
  //   entreprises = results.filter(
  //     resultItem => resultItem.type === "les_entreprises"
  //   )
  // }

  return (
    <Box mx={isMap ? -2 : 0}>
      {loading && <Loader />}
      {!loading && results && (
        <DFPSlotsProvider dfpNetworkId="21617937854" lazyLoad={true}>
          <Animwrapper id="result-wrapper" active={true}>
            {results.length > 0 && (
              <>
                {voies.length >= 5 && (
                  <AnimItemwrapper active={true}>
                    <Teaser
                      collapsedHeight={
                        searchAround !== false && searchPos !== false
                          ? 300
                          : 232
                      }
                      seeMore={
                        "Voir les " + (voies.length - 5) + " voies trouvées"
                      }
                    >
                      {voies.map((resultItem, i) => {
                        return (
                          <Box
                            key={"result-nid-" + resultItem.nid}
                            id={"result-nid-" + resultItem.nid}
                          >
                            <Poi node={resultItem} isMap={isMap} />
                          </Box>
                        )
                      })}
                    </Teaser>
                  </AnimItemwrapper>
                )}
                {voies.length < 5 && (
                  <>
                    {voies.map((resultItem, i) => {
                      return (
                        <AnimItemwrapper
                          active={true}
                          key={"result-nid-" + resultItem.nid}
                        >
                          <Box id={"result-nid-" + resultItem.nid}>
                            <Poi node={resultItem} isMap={isMap} />
                          </Box>
                        </AnimItemwrapper>
                      )
                    })}
                  </>
                )}

                {lieux.length >= 5 && (
                  <AnimItemwrapper active={true}>
                    <Teaser
                      collapsedHeight={
                        searchAround !== false && searchPos !== false
                          ? 300
                          : 232
                      }
                      seeMore={
                        "Voir les " + (lieux.length - 5) + " lieux trouvés"
                      }
                    >
                      {lieux.map((resultItem, i) => {
                        return (
                          <Box
                            key={"result-nid-" + resultItem.nid}
                            id={"result-nid-" + resultItem.nid}
                          >
                            <Poi node={resultItem} isMap={isMap} />
                          </Box>
                        )
                      })}
                    </Teaser>
                  </AnimItemwrapper>
                )}
                {lieux.length < 5 && (
                  <>
                    {lieux.map((resultItem, i) => {
                      return (
                        <AnimItemwrapper
                          active={true}
                          key={"result-nid-" + resultItem.nid}
                        >
                          <Box id={"result-nid-" + resultItem.nid}>
                            <Poi node={resultItem} isMap={isMap} />
                          </Box>
                        </AnimItemwrapper>
                      )
                    })}
                  </>
                )}
                {entreprises.map((resultItem, i) => {
                  indexEntreprise =
                    resultItem.node_type.drupal_internal__target_id ===
                      "company" && indexEntreprise === false
                      ? i
                      : indexEntreprise
                  return (
                    <AnimPubWrapper key={i} active={i < 14} node={resultItem}>
                      {resultItem.node_type.drupal_internal__target_id ===
                        "company" &&
                        (i - indexEntreprise) % 6 === 0 && (
                          <Pub
                            slotId={"result-ad-" + i}
                            readyForAds={
                              !activeAnim && !loading && results.length > 0
                            }
                          />
                        )}
                      <Box id={"result-nid-" + resultItem.drupal_internal__nid}>
                        <Poi node={resultItem} isMap={isMap} />
                      </Box>
                    </AnimPubWrapper>
                  )
                })}
              </>
            )}
            {results.length === 0 && (
              <Box p={3}>
                <Text textAlign="center" fontSize={2}>
                  {message}
                </Text>
                {spellcheckSuggestions?.length > 0 && (
                  <>
                    <Text textAlign="center" fontSize={2} pt={3}>
                      Essayez avec:
                    </Text>
                    {spellcheckSuggestions.map((suggestion, i) => (
                      <Button variant="outline" m={2} key={i}>
                        <Link to={"/recherche/" + suggestion}>
                          {suggestion}
                        </Link>
                      </Button>
                    ))}
                  </>
                )}
              </Box>
            )}
          </Animwrapper>
        </DFPSlotsProvider>
      )}
    </Box>
  )
}

const mapStateToProps = (state) => ({
  isMap: state.global.isMap,
  showFilters: state.global.showFilters,
  spellcheckSuggestions: state.solr.spellcheckSuggestions || [],
  searchAround: state.global.searchAround,
  searchPos: state.solr.searchPos,
})
export default connect(mapStateToProps)(Results)
