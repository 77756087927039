import React, { useState } from "react"
import { Text, Box, Button, Flex } from "rebass/styled-components"
import {
  toggleAroundMe,
  setUserPosition,
} from "../../../redux/actions/globalAction"
import { getUserPosition } from "../../../utils"
import { fetchSolr } from "../../../redux/actions/SolrActions"
import { connect } from "react-redux"
import Switcher from "../Switcher"
import IconPlan from "../icons/IconPlan"
import ModalFilters from "./ModalFilters"

const Filters = ({
  loading,
  items,
  activeFilters,
  showFilters,
  searchAround,
  dispatch,
  word,
  isMap,
  searchPos,
  categorie,
  city,
  categorieParent,
  isMobile,
  userPosition,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const switchSearchType = () => {
      dispatch(toggleAroundMe())
      if (!searchPos) {
        if (isMobile) {
          getUserPosition(function (position) {
            dispatch(
              setUserPosition([
                position.coords.longitude,
                position.coords.latitude,
              ])
            )
          })
        }
      }
      dispatch(fetchSolr(word, categorie, city, categorieParent))
  }
  if (items.length === 0 || !items || !showFilters) {
    return <></>
  }
  const resultsLabel = !loading
    ? items.length + " résultat" + (items.length > 1 ? "s" : "")
    : ""
  return (
    <>
      <Flex
        pt={2}
        alignItems="center"
        justifyContent="space-between"
        px={isMap ? 2 : [2, 0]}
      >
        <Text as="span" fontWeight="bold">
          {resultsLabel}
        </Text>
        <Flex alignItems="center">
          <Button variant="simple" onClick={() => setIsOpen(!isOpen)}>
            <Flex alignItems="center">
              <IconPlan
                icon={activeFilters ? "filter_fill" : "filter_outline"}
                size="24"
                color="primary"
                sx={{
                  position: "relative",
                  "&:after": {
                    content: '""',
                    opacity: activeFilters ? 1 : 0,
                    position: "absolute",
                    top: "-7px",
                    right: "-5px",
                    height: "12px",
                    width: "12px",
                    backgroundColor: "danger",
                    border: "2px solid rgb(255, 255, 255)",
                    borderRadius: "20px",
                    zIndex: 0,
                  },
                }}
              />
              <Text ml={2} fontWeight="bold" display={["none", "block"]}>
                Affiner
              </Text>
            </Flex>
          </Button>
          {isMobile && userPosition && (
            <Button ml={2} variant="simple" onClick={switchSearchType}>
              <Flex alignItems="center">
                <Switcher
                  active={searchAround}
                  isLoading={searchAround !== false && !searchPos}
                  mr={2}
                />
                <Text fontWeight="bold">Autour de moi</Text>
              </Flex>
            </Button>
          )}
        </Flex>
      </Flex>
      <ModalFilters isOpen={isOpen} setIsOpen={setIsOpen} />
      <Box height="1px" bg="#d8d8d8" mt={2} />
    </>
  )
}
const mapStateToProps = (state) => {
  const {
    activeFilters,
    loading,
    items,
    word,
    city,
    categorie,
    categorieParent,
    searchPos,
  } = state.solr
  const { showFilters, searchAround, isMap, isMobile } = state.global

  return {
    activeFilters,
    showFilters,
    searchAround,
    searchPos,
    loading,
    items,
    word,
    isMap,
    city,
    categorie,
    categorieParent,
    isMobile,
    userPosition: state.global.userPosition,
  }
}
export default connect(mapStateToProps)(Filters)
