import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { getValue, createMarker, getDistanceFromNode } from "../../utils"
import loadable from "@loadable/component"

const Map = loadable(() => import("../UI/Map"))
// const NavigationMap = loadable(() => import("../UI/Map/NavigationMap"))

const MapResults = ({
  items,
  currentNode,
  focusedItem,
  className,
  searchAround,
  userPosition,
  location,
}) => {
  // const params = new URLSearchParams(location.search)
  // const showNav = params.get("nav") === "yes"
  // console.log(showNav, "showNav")
  const [markers, setMarkers] = useState([])
  useEffect(() => {
    if (currentNode) {
      setMarkers([createMarker(currentNode, true)])
    } else {
      var tempMarkers = []
      if (items !== false) {
        const currentNid = focusedItem ? focusedItem.drupal_internal__nid : 0
        tempMarkers = items
          .filter(
            (node) =>
              (getValue(node, "field_geofield", "lat") ||
                getValue(node, "field_geofield", "lon")) !== false
          )
          .filter((node) => {
            if (searchAround && userPosition) {
              return getDistanceFromNode(userPosition, node) < 30000
            }
            return true
          })
          .map((node) => {
            return createMarker(node, node.drupal_internal__nid === currentNid)
          })
        if (searchAround && userPosition && tempMarkers.length === 0) {
          tempMarkers = items
            .filter(
              (node) =>
                (getValue(node, "field_geofield", "lat") ||
                  getValue(node, "field_geofield", "lon")) !== false
            )
            .map((node) => {
              return createMarker(
                node,
                node.drupal_internal__nid === currentNid
              )
            })
        }
      }
      setMarkers(tempMarkers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, currentNode, focusedItem, searchAround])
  return <Map className={className} markers={markers} />
  // return showNav && currentNode ? (
  //   <NavigationMap className={className} node={currentNode} />
  // ) : (
  //   <Map className={className} markers={markers} />
  // )
}

const mapStateToProps = (state) => ({
  currentNode: state.currentNode.item,
  focusedItem: state.currentNode.focusedItem,
  items: state.solr.items,
  searchAround: state.global.searchAround,
  userPosition: state.global.userPosition,
  location: state.global.location,
})

export default connect(mapStateToProps)(MapResults)
