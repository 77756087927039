import React from "react"
import { Box } from "rebass/styled-components"
import { Spinner } from "react-awesome-spinners"

const Switcher = ({ active, isLoading, ...props }) => {
  return (
    <Box
      sx={{
        width: "48px",
        height: "24px",
        borderRadius: "12px",
        position: "relative",
        backgroundColor: active ? "primary" : "danger",
        transition: "all 100ms ease",
      }}
      {...props}
    >
      <Box
        sx={{
          width: "16px",
          height: "16px",
          borderRadius: "16px",
          top: "0",
          left: active ? 24 : 0,
          position: "absolute",
          margin: "4px",
          backgroundColor: "white",
          transition: "all 100ms ease",
          "&>div": {
            transform: "scale(.2)",
            marginLeft: "-24px",
            marginTop: "-24px",
          },
        }}
      >
        {isLoading && <Spinner color="#8c8c8c" />}
      </Box>
    </Box>
  )
}
export default Switcher
