import React from "react"
import { Box, Flex, Text } from "rebass/styled-components"
import unescape from "lodash/unescape"
import { getValue } from "../../../utils"

import IconPlan from "../icons/IconPlan"
const Address = ({ node, hideBp, hideIcon, onResult, ...props }) => {
  onResult = onResult || false
  hideBp = hideBp || false
  hideIcon = hideIcon || false
  const adresse1 = [
    getValue(node, "field_address_nb", false, "") + "",
    getValue(
      node,
      "relationships",
      "field_street.field_street_prefix",
      ""
    ),
    getValue(node, "relationships", "field_street.title", ""),
  ]
    .filter((line) => line.length > 0)
    .join(" ")
  const code = getValue(node, "field_zip_code", false, "") + ""
  const adresse2 = [
    getValue(node, "field_district", "title", ""),
    [code, getValue(node, "field_city", "name", "")]
      .filter((line) => line.length > 0)
      .join(" "),
  ]
    .filter((line) => line.length > 0)
    .join(" - ")

  const adresseResult = [
    getValue(node, "field_district", "title", ""),
    (code === "" ? "" : code + " ") + getValue(node, "field_city", "name", ""),
  ]
    .filter((line) => line.length > 0)
    .join(" - ")
  const bp = [
    getValue(node, "field_mailbox", false, "") + "",
    getValue(node, "field_mailbox_text", "", ""),
  ]
    .filter((line) => line.length > 0)
    .join(" - ")
  const complement = getValue(node, "field_street_text")
  // return (
  //   <>
  //     <Text mt={3}>{adresse1}</Text>
  //   </>
  // )
  if (adresse1 === "" && adresse2 === "") {
    return <></>
  }
  if (onResult) {
    return (
      <Flex alignItems="flex-start" {...props}>
        {!hideIcon && <IconPlan icon="map-marker" size="24" color="primary" />}
        <Box pl={hideIcon ? 0 : 1}>
          <Text>{unescape(adresse1).replace("&#039;", "'")}</Text>
          <Text>{unescape(adresseResult)}</Text>
        </Box>
      </Flex>
    )
  }
  return (
    <Flex alignItems="flex-start" {...props}>
      {!hideIcon && <IconPlan icon="map-marker" size="24" color="primary" />}
      <Box pl={hideIcon ? 0 : 1}>
        <Text>{unescape(adresse1).replace("&#039;", "'")}</Text>
        {complement && <Text>{unescape(complement)}</Text>}
        <Text>{unescape(adresse2)}</Text>
        {bp && !hideBp && <Text mt={3}>BP {unescape(bp)}</Text>}
      </Box>
    </Flex>
  )
}
export default Address
