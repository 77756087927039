import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { connect } from "react-redux"
import {
  fetchSolr,
  solrResetItems,
  setLastListPath,
  resultSetWord,
  // solrSetItems,
} from "../redux/actions/SolrActions"
import { createCategorieBreadCrumb } from "../utils"
import Page from "./Page"
import Results from "./results/Results"
import SubCats from "../components/UI/Categories/SubCats"

const Categorie = ({
  data,
  location,
  items,
  loading,
  dispatch,
  currentLocation,
  city,
  word,
}) => {
  const { taxonomyTermCat: categorie } = data
  const isParent = categorie?.relationships?.parent.length === 0
  categorie.relationships.node__company =
    categorie.relationships.node__company || []
  // const results = categorie.relationships.node__company.sort((a, b) => {
  //   if (
  //     a.relationships.field_offer.weight === b.relationships.field_offer.weight
  //   ) {
  //     return a.title.localeCompare(b.title)
  //   }
  //   return (
  //     a.relationships.field_offer.weight - b.relationships.field_offer.weight
  //   )
  // })
  // console.log(node)
  const crumbs = createCategorieBreadCrumb(categorie)
  useEffect(() => {
    if (currentLocation.pathname !== location.pathname) {
      dispatch(resultSetWord(""))
      dispatch(solrResetItems())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined" && !loading && !items && word === "") {
      if (!isParent) {
       dispatch(fetchSolr(false, categorie.drupal_internal__tid, city, false))
        // dispatch(solrSetItems(results))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loading,
    items,
    categorie.drupal_internal__tid,
    categorie.relationships,
    dispatch,
  ])

  useEffect(() => {
    if (!loading && items) {
      dispatch(setLastListPath(location.pathname))
    }
    if (!loading && items.length === 0) {
      if (!isParent) {
      // dispatch(solrSetItems(results))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, items])

  const description =
    "Retrouvez toutes les entreprises du secteur " +
    categorie.name +
    " en Nouvelle-Calédonie"

  return (
    <Page
      crumbs={crumbs}
      title={categorie.name}
      location={location}
      description={description}
      filters={!isParent}
      showCrumbs={true}
    >
      {isParent ? (
        <SubCats categories={data.allTaxonomyTermCat.nodes} />
      ) : (
        <>{items && <Results loading={false} results={items} />}</>
      )}
    </Page>
  )
}
const mapStateToProps = (state) => ({
  items: state.solr.items,
  loading: state.solr.loading,
  city: state.solr.city,
  currentLocation: state.global.location,
  word: state.solr.word,
})

export default connect(mapStateToProps)(Categorie)

export const categorieQuery = graphql`
  fragment CategoryParents on taxonomy_term__catRelationships {
    parent {
      name
      path {
        alias
      }
      relationships {
        parent {
          name
          path {
            alias
          }
          relationships {
            parent {
              name
              path {
                alias
              }
              relationships {
                parent {
                  name
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  query CategorieTemplate($category_id: String!) {
    taxonomyTermCat(drupal_id: { eq: $category_id }) {
      drupal_internal__tid
      name
      description {
        processed
        value
      }
      path {
        alias
      }
      relationships {
        node__company {
          ...CompanyNode
        }
        ...CategoryParents
      }
    }
    allTaxonomyTermCat(
      filter: {
        relationships: {
          parent: { elemMatch: { drupal_id: { eq: $category_id } } }
          node__company: { elemMatch: { id: { ne: null } } }
        }
      }
      sort: { name: ASC }
    ) {
      nodes {
        drupal_internal__tid
        drupal_id
        name
        path {
          alias
        }
        relationships {
          parent {
            drupal_id
          }
          node__company {
            drupal_id
          }
        }
      }
    }
  }
`
