import React from "react"
import { withTheme } from "styled-components"
import { Box } from "rebass/styled-components"
import { getValue, getNodePath, createMarker } from "../utils"
import { graphql } from "gatsby"
import colors from "../theme/colors"
import Seo from "../components/layout/seo"

import loadable from "@loadable/component"

const Map = loadable(() => import("../components/UI/Map"))

const IframePageTemplate = ({ data, theme, ...props }) => {
  const { nodeCompany: node } = data
  if (!node) {
    return <></>
  }
  var path = getNodePath(node, true)
  const marker = createMarker(node, true)
  marker.title =
    '<p style="padding-bottom:5px;margin: 0;font-size:1.5em;font-weight:bold;">' +
    node.title +
    '</p><p style="padding-bottom:5px;margin: 0;">' +
    getValue(node, "field_co_cats", "0.name", "") +
    '</p><a href="' +
    path +
    '" target="_blank" style="background:' +
    colors.primary +
    ';color:#fff;border-radius:4px;padding:10px;display: inline-block;">Agrandir le plan</a><br>'

  return (
    <Box
      width="100%"
      height={"100%"}
      sx={{
        ".iframe-map": {
          position: "absolute",
          zIndex: 3,
          width: "100%",
          height: "100%",
        },
      }}
    >
      <Seo
        title={node.title}
        description={getValue(node, "field_meta_description", "value", "")}
      />
      <Map className="iframe-map" markers={[marker]} />
    </Box>
  )
}
export default withTheme(IframePageTemplate)



export const nodeQuery = graphql`
  query IframePageTemplate($nid: Int!) {
    nodeCompany(drupal_internal__nid: { eq: $nid }) {
      ...CompanyNode
    }
  }
`
