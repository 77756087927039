import React, { useState } from "react"
import { Flex, Text, Button, Box, Heading } from "rebass/styled-components"
import IconPlan from "../icons/IconPlan"
import ModalCustom from "../Modal"
import { connect } from "react-redux"
import { getFavoris } from "../../../redux/actions/currentUserActions"
import { axiosInstance } from "../../../utils"
const FavorisButton = ({
  node,
  favoris,
  dispatch,
  isMap,
  width,
  displayLabel,
  user,
  buttonSx,
  token,
}) => {
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  if (!user) {
    return null
  }
  const isFlaged =
    favoris.filter(
      (fav) => parseInt(fav.nid) === parseInt(node.drupal_internal__nid)
    ).length > 0
  const flag = () => {
    if (loading) {
      return
    }
    const action = isFlaged ? "unflag" : "flag"
    if (isFlaged && !isOpen) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
      setLoading(true)
      axiosInstance
        .get(`/v2/${action}/${node.drupal_internal__nid}`, {
          crossdomain: true,
          headers: {
            "Content-Type": "application/json",
            "X-AUTH-TOKEN": token,
          },
        })
        .then((res) => {
          dispatch(getFavoris()).then(() => setLoading(false))
        })
    }
  }
  buttonSx = buttonSx || {}
  width = width || (isMap ? [1 / 6] : [1 / 6, "auto", "auto", 1])
  displayLabel =
    displayLabel ||
    (isMap ? ["none", "block", "none"] : ["none", "none", "block"])
  return (
    <>
      <ModalCustom
        isOpen={isOpen !== false}
        setIsOpen={setIsOpen}
        containerSx={{
          minWidth: ["100%", "auto"],
          maxWidth: ["100%", "540px"],
        }}
      >
        <Box p={4} pt={0}>
          <Heading as="h3" mb={4} textAlign="center">
            Attention
          </Heading>
          <Text pb={4} textAlign="center">
            Voulez vous vraiment retirer cette fiche des favoris ?
          </Text>
          <Flex py={4} justifyContent="center">
            <Button variant="topAction" mx={1} onClick={flag}>
              Retirer des favoris
            </Button>
            <Button mx={1} onClick={() => setIsOpen(false)}>
              Non, revenir à la liste
            </Button>
          </Flex>
        </Box>
      </ModalCustom>
      <Flex px={1} width={width}>
        <Button
          variant="topAction"
          onClick={flag}
          as="div"
          p={2}
          px={isMap ? [2, 3] : [2]}
          mt={isMap ? 0 : [0, 0, 0, 2]}
          display="flex"
          width={1}
          justifyContent={isMap ? "center" : ["center", "center", "flex-start"]}
          sx={{ cursor: loading ? "not-allowed" : "pointer", ...buttonSx }}
        >
          <Flex
            alignItems="center"
            opacity={loading ? 0.5 : 1}
            sx={{ transition: "base" }}
          >
            <IconPlan
              icon={isFlaged ? "star_fill" : "start_outline"}
              color={isFlaged ? "golden" : "primary"}
              size="24"
            />
            <Text pl={2} display={displayLabel}>
              Favoris
            </Text>
          </Flex>
        </Button>
      </Flex>
    </>
  )
}

const mapStateToProps = (state) => ({
  favoris: state.currentUser.favoris,
  user: state.currentUser.user,
  token: state.currentUser.token,
})
export default connect(mapStateToProps)(FavorisButton)
