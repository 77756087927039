import React from "react"
import { Box, Flex, Text, Button } from "rebass/styled-components"
import { Link } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"
import { getValue, sendEvent, hasRdv } from "../../utils"
import IconPlan from "../../components/UI/icons/IconPlan"
import { connect } from "react-redux"
import FavorisButton from "../../components/UI/poi/FavorisButton"
import ShareButton from "../../components/UI/poi/ShareButton"

const AnimateWrapper = ({ x, children, isMap, className = "" }) => {
  if (isMap) {
    return (
      <motion.div
        className={className}
        initial="closed"
        animate="open"
        exit="closed"
        variants={{
          open: { opacity: 1, x: 0, height: "auto" },
          closed: { opacity: 0, x: x, height: 0 },
        }}
      >
        {children}
      </motion.div>
    )
  }
  return <>{children}</>
}
const AnimatePresenceWrapper = ({ children, isMap }) => {
  if (isMap) {
    return <AnimatePresence initial={false}>{children}</AnimatePresence>
  }
  return <>{children}</>
}

const Actions = ({
  node,
  isMap,
  url,
  actionVisible,
  isMobile,
  userPosition,
  ...props
}) => {
  const lien = getValue(node, "field_site_internet", "url")
  const mail = getValue(node, "field_adresse_email", "email")
  const standard = getValue(node, "field_telephone_standard", "Phone")
  const portable = getValue(node, "field_telephone_portable", "Phone")
  const phone = standard || portable
  return (
    <Box {...props}>
      <Flex
        mb={2}
        mt={actionVisible ? 2 : 0}
        flexWrap="wrap"
        justifyContent="flex-end"
        {...props}
      >
        <Box display="none">
          <Flex
            flexWrap="wrap"
            justifyContent="flex-start"
            pl={isMap ? 2 : [0, 0, 2]}
          >
            <AnimatePresenceWrapper>
              {actionVisible && (
                <AnimateWrapper x="-300px" isMap={isMap}>
                  <Box display={isMap ? "block" : ["block", "none", "none"]}>
                    {phone && (
                      <a
                        aria-label="appeler"
                        rel="noreferrer"
                        href={phone.link}
                        title="Appeler"
                        className="action-link"
                      >
                        <Button as="div" variant="simple" mr={1}>
                          <IconPlan
                            flexWrap="wrap"
                            icon="call_outline-copy"
                            size="17"
                          />
                        </Button>
                      </a>
                    )}
                    {lien && (
                      <a
                        aria-label="voir le site"
                        target="_blank"
                        rel="noreferrer"
                        href={lien}
                        title="Visiter le site"
                        className="action-link"
                      >
                        <Button as="div" variant="simple" mr={1}>
                          <IconPlan
                            flexWrap="wrap"
                            icon="lien-url-link"
                            size="17"
                          />
                        </Button>
                      </a>
                    )}
                    {mail && (
                      <a
                        aria-label="envoyer un mail"
                        rel="noreferrer"
                        href={"mailto:" + mail}
                        title="Envoyer un email"
                        className="action-link"
                      >
                        <Button as="div" variant="simple" mr={1}>
                          <IconPlan flexWrap="wrap" icon="Email" size="17" />
                        </Button>
                      </a>
                    )}
                  </Box>
                </AnimateWrapper>
              )}
            </AnimatePresenceWrapper>
          </Flex>
        </Box>
        <Flex
          flexWrap="wrap"
          justifyContent="flex-end"
          width="100%"
          sx={{
            ".motion-wrapper": {
              width: ["100%", "100%", isMap ? "100%" : "auto"],
              pl: [2, 2, "unset"],
              // mx: ["-10px"],
            },
          }}
        >
          <AnimatePresenceWrapper>
            {actionVisible && (
              <AnimateWrapper
                x="300px"
                isMap={isMap}
                className={"motion-wrapper"}
              >
                <Flex
                  alignItems="center"
                  justifyContent="flex-end"
                  flexDirection={"row"}
                  width={["100%", "100%", isMap ? "100%" : "auto"]}
                  // mx={-1}
                  sx={{
                    a: {
                      width: "100%",
                    },
                  }}
                >
                  {hasRdv(node) && (
                    <Flex px={1}>
                      <Link to={url + "#rdv"}>
                        <Button
                          variant="purpleGradiant"
                          as="div"
                          width={1}
                          px={isMap ? [2, 3] : [2]}
                        >
                          <Flex
                            alignItems="center"
                            justifyContent="flex-start"
                            flexDirection={"row"}
                            flexWrap={["nowrap"]}
                          >
                            <IconPlan icon="calendar-squares" size="24" />
                            <Flex
                              pl={[0, 1, 0]}
                              alignItems="flex-start"
                              justifyContent="flex-start"
                              flexDirection={"column"}
                            >
                              <Text
                                fontSize="12px"
                                display={["none", "block", "none"]}
                              >
                                Prendre
                              </Text>
                              <Text
                                fontSize="12px"
                                display={["none", "block", "none"]}
                              >
                                RDV
                              </Text>
                            </Flex>
                          </Flex>
                        </Button>
                      </Link>
                    </Flex>
                  )}

                  <FavorisButton
                    node={node}
                    isMap={isMap}
                    width={["auto"]}
                    buttonSx={{ mt: "0 !important" }}
                  />
                  <ShareButton
                    isMobile={isMobile}
                    node={node}
                    isMap={isMap}
                    width={["auto"]}
                    buttonSx={{ mt: "0 !important" }}
                  />
                  {/* {isMobile && userPosition && (
                    <Flex px={1} width={[1 / 3, 1 / 3, isMap ? 1 / 3 : "auto"]}>
                      <Link
                        to={url + "#itineraire"}
                        onClick={() =>
                          sendEvent(
                            "itineraire",
                            "clic-itineraire",
                            node.drupal_internal__nid,
                            1
                          )
                        }
                      >
                        <Button
                          variant="blueGradiant"
                          as="div"
                          width={1}
                          px={isMap ? [2, 3] : [2]}
                        >
                          <Flex
                            alignItems="center"
                            justifyContent="flex-start"
                            flexDirection={"row"}
                            flexWrap={["nowrap"]}
                          >
                            <IconPlan icon="itineraire" size="24" />
                            <Text pl={1} fontSize="12px">
                              Itineraire
                            </Text>
                          </Flex>
                        </Button>
                      </Link>
                    </Flex>
                  )} */}
                  <Flex px={1} width={["auto"]}>
                    <Link to={url}>
                      <Button
                        variant="primary"
                        as="div"
                        width={[1]}
                        px={["5px", 3]}
                      >
                        <Flex
                          alignItems="center"
                          justifyContent="flex-start"
                          flexDirection={"row"}
                          height="26px"
                          flexWrap={["nowrap"]}
                        >
                          <IconPlan icon="arrow_right" size="24" />
                          <Text pl={1} fontSize="12px" textAlign="left">
                            Voir la fiche
                          </Text>
                        </Flex>
                      </Button>
                    </Link>
                  </Flex>
                </Flex>
              </AnimateWrapper>
            )}
          </AnimatePresenceWrapper>
        </Flex>
      </Flex>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  isMobile: state.global.isMobile,
  userPosition: state.global.userPosition,
})
export default connect(mapStateToProps)(Actions)
