import React, { useState, useEffect } from "react"
import { Box, Text } from "rebass/styled-components"
import forEach from "lodash/forEach"

const PasswordMeter = ({ value, name, showVerdicts }) => {
  value = value || ""
  const [color, setColor] = useState("#ff0000")
  const [width, setWidth] = useState("0%")
  const [errors, setErrors] = useState([])
  const [verdict, setVerdict] = useState("")
  const calculateScore = function(word) {
    var totalScore = 0
    forEach(options.rules, function(active, rule) {
      if (active === true) {
        var score = options.ruleScores[rule],
          result = options.validationRules[rule](options, word, score)
        if (result) {
          totalScore += result
        }
      }
    })
    totalScore = word === "" ? false : totalScore
    setProgressBar(totalScore)
    return totalScore
  }

  useEffect(() => {
    calculateScore(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])
  const options = {
    errors: [],
    // Options
    minChar: 8,
    errorMessages: {
      password_to_short: "Votre mot de passe est trop court.",
      same_as_username:
        "Votre mot de passe ne doit pas etre identique à votre identifiant",
      is_mail: "Votre mot de passe ne doit pas etre une adresse email",
    },
    scores: [17, 26, 40, 50],
    verdicts: ["Très faible", "Faible", "Moyen", "Fort", "Très fort"],
    showVerdicts: showVerdicts || false,
    raisePower: 1.4,
    onLoad: undefined,
    onKeyUp: undefined,
    viewports: {
      progress: undefined,
      verdict: undefined,
      errors: undefined,
    },
    // Rules stuff
    ruleScores: {
      wordNotEmail: -100,
      wordLength: -100,
      wordSimilarToUsername: -100,
      wordLowercase: 1,
      wordUppercase: 3,
      wordOneNumber: 3,
      wordThreeNumbers: 5,
      wordOneSpecialChar: 3,
      wordTwoSpecialChar: 5,
      wordUpperLowerCombo: 2,
      wordLetterNumberCombo: 2,
      wordLetterNumberCharCombo: 2,
    },
    rules: {
      wordNotEmail: true,
      wordLength: true,
      wordSimilarToUsername: true,
      wordLowercase: true,
      wordUppercase: true,
      wordOneNumber: true,
      wordThreeNumbers: true,
      wordOneSpecialChar: true,
      wordTwoSpecialChar: true,
      wordUpperLowerCombo: true,
      wordLetterNumberCombo: true,
      wordLetterNumberCharCombo: true,
    },
    validationRules: {
      wordNotEmail: function(options, word, score) {
        var emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
        if (word.search(emailRegEx) !== -1) {
          // setErrors(errors.push(options.errorMessages.is_mail));
          options.errors.push(options.errorMessages.is_mail)
        }
        return word.search(emailRegEx) !== -1 && score
      },
      wordLength: function(options, word, score) {
        var wordlen = word.length,
          lenScore = Math.pow(wordlen, options.raisePower)
        if (wordlen < options.minChar) {
          lenScore = lenScore + score
          options.errors.push(options.errorMessages.password_to_short)
        }
        return lenScore
      },
      wordSimilarToUsername: function(options, word, score) {
        if (name && word.toLowerCase().match(name.toLowerCase())) {
          options.errors.push(options.errorMessages.same_as_username)
          return score
        }
        return true
      },
      wordLowercase: function(options, word, score) {
        return word.match(/[a-z]/) && score
      },
      wordUppercase: function(options, word, score) {
        return word.match(/[A-Z]/) && score
      },
      wordOneNumber: function(options, word, score) {
        return word.match(/\d+/) && score
      },
      wordThreeNumbers: function(options, word, score) {
        return word.match(/(.*[0-9].*[0-9].*[0-9])/) && score
      },
      wordOneSpecialChar: function(options, word, score) {
        return word.match(/.[!,@,#,$,%,^,&,*,?,_,~]/) && score
      },
      wordTwoSpecialChar: function(options, word, score) {
        return (
          word.match(/(.*[!,@,#,$,%,^,&,*,?,_,~].*[!,@,#,$,%,^,&,*,?,_,~])/) &&
          score
        )
      },
      wordUpperLowerCombo: function(options, word, score) {
        return word.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/) && score
      },
      wordLetterNumberCombo: function(options, word, score) {
        return word.match(/([a-zA-Z])/) && word.match(/([0-9])/) && score
      },
      wordLetterNumberCharCombo: function(options, word, score) {
        return (
          word.match(
            /([a-zA-Z0-9].*[!,@,#,$,%,^,&,*,?,_,~])|([!,@,#,$,%,^,&,*,?,_,~].*[a-zA-Z0-9])/
          ) && score
        )
      },
    },
  }
  const setProgressBar = function(score) {
    setErrors([...options.errors])
    if (!score) {
      setWidth("0%")
      setColor("#ff0000")
      if (options.showVerdicts) {
        setVerdict(options.verdicts[0])
      }
    } else if (score < options.scores[0]) {
      setWidth("5%")
      setColor("#ef4e04")
      if (options.showVerdicts) {
        setVerdict(options.verdicts[0])
      }
    } else if (score >= options.scores[0] && score < options.scores[1]) {
      setWidth("25%")
      setColor("#ef8d04")
      if (options.showVerdicts) {
        setVerdict(options.verdicts[1])
      }
    } else if (score >= options.scores[1] && score < options.scores[2]) {
      setWidth("50%")
      setColor("#efe704")
      if (options.showVerdicts) {
        setVerdict(options.verdicts[2])
      }
    } else if (score >= options.scores[2] && score < options.scores[3]) {
      setWidth("75%")
      setColor("#9cce07")
      if (options.showVerdicts) {
        setVerdict(options.verdicts[3])
      }
    } else if (score >= options.scores[3]) {
      setWidth("100%")
      setColor("#1fce08")
      if (options.showVerdicts) {
        setVerdict(options.verdicts[4])
      }
    }
  }

  return (
    <Box
      opacity={value === "" ? 0 : 1}
      sx={{
        transition: "all 300ms ease",
        maxHeight: value === "" ? 0 : "200px",
        overflow: "hidden",
      }}
    >
      <Box py={2}>
        <Text>{verdict}</Text>
        {errors.length > 0 && (
          <Box>
            <Text variant="tip">Conseils:</Text>
            {errors.map((error, i) => (
              <Text key={i} variant="tip">
                {error}
              </Text>
            ))}
          </Box>
        )}
      </Box>
      <Box
        height="5px"
        sx={{
          transition: "all 300ms ease",
          borderRadius: "5px",
        }}
        width={width}
        backgroundColor={color}
      ></Box>
    </Box>
  )
}
export default PasswordMeter
