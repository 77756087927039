/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Box, Flex } from "rebass/styled-components"
import { DFPSlotsProvider, AdSlot, DFPManager } from "react-dfp"
// import Sticky from './Sticky'
import MessengerBtn from "./MessengerBtn"

// import SearchBar from "../UI/SearchBar"
import Breadcrumb from "../UI/Breadcrumb"
import Container from "../UI/Container"
import Header from "./header"
import Footer from "./Footer"
import RatioBox from "../UI/RatioBox"
import Filters from "../UI/SearchBar/Filters"

const AnnuaireLayout = ({ children, location, crumbs }) => {
  useEffect(() => {
    DFPManager.refresh()
  }, [])
  var onHome = false
  if (location) {
    if (
      location.pathname === "/" ||
      location.pathname === "/annuaire" ||
      location.pathname === "/annuaire/"
    ) {
      onHome = true
    }
  }

  return (
    <DFPSlotsProvider dfpNetworkId="21617937854" lazyLoad={true}>
      <Header />
      <Flex justifyContent="center" width="100%">
        <Flex width={[1, 1, 1, "auto"]}>
          <Box display={["none", "none", "none", "block"]} overflow="hidden">
            <Flex justifyContent="flex-end">
              <AdSlot sizes={[[400, 955]]} adUnit="habillage-portail-left" />
            </Flex>
          </Box>
          <Container px="0px !important" minWidth={[0, 0, 0, "960px"]}>
            <Box lineHeight="0" overflow="hidden">
              <Box
                sx={{
                  transform: ["scale(0.37)", "scale(.5)", "scale(.8)", "none"],
                }}
              >
                <RatioBox ratio={210 / 960}>
                  <AdSlot sizes={[[960, 210]]} adUnit="habillage-portail-top" />
                </RatioBox>
              </Box>
            </Box>
            <Container
              bg="#FFF"
              sx={{ minHeight: "100vh", minWidth: "0 !important" }}
            >
              {!onHome && (
                <Box pt={[0, 0, 4]} mx={[-2, -2, 0]}>
                  <Breadcrumb
                    display={["none", "none", "block"]}
                    crumbs={crumbs}
                  />
                  <Filters />
                </Box>
              )}

              <Box pt={4}>{children}</Box>
            </Container>
          </Container>
          <Box display={["none", "none", "none", "block"]} overflow="hidden">
            <AdSlot sizes={[[400, 955]]} adUnit="habillage-portail-right" />
          </Box>
        </Flex>
      </Flex>
      <Footer />
      <MessengerBtn />
    </DFPSlotsProvider>
  )
}

AnnuaireLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

const mapStateToProps = state => ({
  hasCurrent: state.currentNode.hasCurrent,
  location: state.global.location,
})
export default connect(mapStateToProps)(AnnuaireLayout)
