import React, { useState, useEffect } from "react"
import { Box, Flex, Button, Heading, Text } from "rebass/styled-components"
import { Input, Label } from "@rebass/forms"
import { withTheme } from "styled-components"
import { navigate, Link } from "gatsby"
import { connect } from "react-redux"
import { solrReset, resultSetWord } from "../../../redux/actions/SolrActions"
import { currentNodeReset } from "../../../redux/actions/currentNodeActions"

import IconPlan from "../icons/IconPlan"
import Axios from "axios"
import Loader from "../../UI/Loader"
import useKey from "../../../utils/useKeyPressed"
const SearchBar = ({ currentWord, theme, dispatch, isMap, id, ...props }) => {
  id = id || "search"
  const [word, setWord] = useState(currentWord)
  const [autocompleteResults, setAutocompleteResults] = useState([])
  const [loading, setLoading] = useState(false)
  const CancelToken = Axios.CancelToken
  const [source, setSource] = useState(CancelToken.source())
  const [selectedIndex, setSelectedIndex] = useState(0)

  const enter = useKey("Enter")
  const tab = useKey("Tab")
  const arrowUp = useKey("ArrowUp")
  const arrowDown = useKey("ArrowDown")

  useEffect(() => {
    if (arrowUp) {
      setSelectedIndex(prevIndex =>
        prevIndex !== 1 ? prevIndex - 1 : autocompleteResults.length
      )
    }
    if (arrowDown || tab) {
      setSelectedIndex(prevIndex =>
        prevIndex !== autocompleteResults.length ? prevIndex + 1 : 1
      )
    }
    if (enter) {
      if (selectedIndex > 0) {
        let item = autocompleteResults[selectedIndex - 1]
        const callback =
          item.type !== "categorie" ? handleItemValue : handleItemUrl
        callback(item)
      } else {
        goTo()
      }
    }
  }, [arrowUp, tab, arrowDown, enter])

  useEffect(() => {
    setWord(currentWord)
  }, [currentWord])
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     fetchData(word)
  //   }, 1000)
  //   return () => clearTimeout(timer)
  // }, [word])

  const closeAutocomplete = () => {
    source.cancel("Operation canceled by the user.")
    setSource(CancelToken.source())
    dispatch(solrReset())
    dispatch(currentNodeReset())
    setLoading(false)
    setAutocompleteResults([])
  }

  const handleItemValue = item => {
    setWord(item.value)
    closeAutocomplete()
    dispatch(resultSetWord(item.value))
    navigate("/recherche/" + item.value, { state: { isMap: isMap } })
  }
  const handleItemUrl = item => {
    closeAutocomplete()
    navigate(item.url, { state: { isMap: isMap } })
  }
  const goTo = () => {
    closeAutocomplete()
    if (word !== "") {
      dispatch(resultSetWord(word))
      navigate("/recherche/" + word, { state: { isMap: isMap } })
    }
  }
  var tabIndex = 0
  return (
    <Box {...props}>
      <Flex px={[2]} py={[1, 1, 2]} variant="searchBar">
        <Flex
          bg="white"
          sx={{ borderRadius: "4px", position: "relative" }}
          width="100%"
          justifyContent="space-between"
        >
          <Flex alignItems="center" width="100%" sx={{ position: "relative" }}>
            <Label htmlFor={id} width="0" height="0">
              Rechercher
            </Label>
            <Input
              variant="searchBar"
              id={id}
              fontSize="18rem"
              theme={theme}
              value={word}
              placeholder="Qui, quoi, où ?"
              onChange={e => setWord(e.target.value)}
              onKeyPress={event => {
                if (event.key === "Enter") {
                  event.preventDefault()
                  //goTo()
                }
              }}
              tabIndex={0}
              autoComplete="off"
            />
            {(autocompleteResults.length > 0 || loading) && (
              <Box
                sx={{
                  borderRadius: "4px",
                  border: "1px solid",
                  position: "absolute",
                  top: "100%",
                  left: "0",
                }}
                marginTop="10px"
                bg="white"
                width="100%"
                height="auto"
              >
                <Box>
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      {autocompleteResults.some(
                        item => item.type === "categorie"
                      ) && (
                        <Heading color="text" p={2} py={1} as="h3">
                          Catégories
                        </Heading>
                      )}
                      {autocompleteResults
                        .filter(item => item.type === "categorie")
                        .map((item, index) => {
                          tabIndex++
                          return (
                            <Box key={index} color="text">
                              <Link to={item.url}>
                                <Text
                                  p={2}
                                  py={1}
                                  color="text"
                                  tabIndex={tabIndex}
                                  sx={{
                                    "&:hover": { backgroundColor: "gray30" },
                                    backgroundColor:
                                      tabIndex === selectedIndex
                                        ? "gray30"
                                        : "white",
                                  }}
                                  onKeyPress={event => {
                                    if (event.key === "Enter") {
                                      event.preventDefault()
                                    }
                                  }}
                                >
                                  {item.title}
                                </Text>
                              </Link>
                            </Box>
                          )
                        })}
                      {autocompleteResults.some(
                        item => item.type === "poi"
                      ) && (
                        <Heading color="text" p={2} py={1} as="h3">
                          Entreprises
                        </Heading>
                      )}
                      {autocompleteResults
                        .filter(item => item.type === "poi")
                        .map((item, index) => {
                          tabIndex++
                          return (
                            <Box key={index}>
                              <Text
                                color="text"
                                p={2}
                                py={1}
                                sx={{
                                  cursor: "pointer",
                                  "&:hover": { backgroundColor: "gray30" },
                                  backgroundColor:
                                    tabIndex === selectedIndex
                                      ? "gray30"
                                      : "white",
                                }}
                                tabIndex={tabIndex}
                                onKeyPress={event => {
                                  if (event.key === "Enter") {
                                    event.preventDefault()
                                  }
                                }}
                                onClick={() => {
                                  handleItemValue(item)
                                }}
                              >
                                {item.value}
                              </Text>
                            </Box>
                          )
                        })}
                    </>
                  )}
                </Box>
              </Box>
            )}
          </Flex>
          <Button
            aria-label="Rechercher"
            minWidth={["42px", "42px", "50px"]}
            width={["42px", "42px", "50px"]}
            height={["auto", "auto", "48px"]}
            color="text"
            m="0"
            p="0"
            variant="transparent"
            onClick={() => goTo()}
            sx={{
              position: "relative",
              "&:before": {
                position: "absolute",
                bg: "gray50",
                content: "''",
                top: "5px",
                bottom: "5px",
                width: "1px",
                left: "0",
              },
            }}
          >
            <IconPlan icon="Loupe" size="29" />
          </Button>
        </Flex>
      </Flex>
    </Box>
  )
}
const mapStateToProps = state => ({
  currentWord: state.solr.word,
  isMap: state.global.isMap,
})
export default connect(mapStateToProps)(withTheme(SearchBar))
