import React from "react"
import { Box, Text, Heading, Button } from 'rebass/styled-components'
import { Link } from "gatsby"
import Page from "../templates/Page";

import { store } from "../redux/store"
const Page404 = ({ location }) =>{

console.log(store.getState(),"store")
return (
  <Page title="404 : Page non trouvée" location={location}>
    <Box p={5}>
      <Text py={5} textAlign="center" fontSize="80rem">404</Text>
      <Heading py={5} textAlign="center">Page non trouvée</Heading>
      <Text textAlign="center">La page demandée n'a pas pu être trouvée.</Text>
      <Box py={5} textAlign="center">
        <Link to="/">
          <Button>Retour à l'accueil</Button>
        </Link>
      </Box>
    </Box>
  </Page>
)}
export default Page404